import React from "react";
import Navbar from "./components/Navbar";
import { BsFillCaretRightFill } from "react-icons/bs";

const VideoFile = () => {
  return (
    <div className=" absolute h-full w-full right-0 p-4 lg:p-4 overflow-x-auto">
      <Navbar />

      <div className="mt-8 w-full max-w-[1000px] p-5 pb-10 mb-10 gap-5 columns-1 sm:columns-2 md:columns-3 lg:sm:columns-4 space-y-5">
        <div className="relative cursor-pointer">
          <img
            src={require("./images/image-1.png")}
            className="h-full w-full"
            alt=""
          />
          <div className="absolute bottom-5 left-5 text-white">
            <p>img 123213</p>
            <p>05 jan 2004 02:00</p>
          </div>
          <div className="w-full h-full absolute top-0 left-0 flex justify-center items-center">
            {/* <img src={<play/>} className='h-full w-full' alt="" /> */}
            <BsFillCaretRightFill className="text-6xl text-white" />
          </div>
        </div>
        <div className="relative cursor-pointer">
          <img
            src={require("./images/image-2.png")}
            className="h-full w-full"
            alt=""
          />
          <div className="absolute bottom-5 left-5 text-white">
            <p>img 123213</p>
            <p>05 jan 2004 02:00</p>
          </div>
          <div className="w-full h-full absolute top-0 left-0 flex justify-center items-center">
            {/* <img src={<play/>} className='h-full w-full' alt="" /> */}
            <BsFillCaretRightFill className="text-6xl text-white" />
          </div>
        </div>
        <div className="relative cursor-pointer">
          <img
            src={require("./images/image-3.png")}
            className="h-full w-full"
            alt=""
          />
          <div className="absolute bottom-5 left-5 text-white">
            <p>img 123213</p>
            <p>05 jan 2004 02:00</p>
          </div>
          <div className="w-full h-full absolute top-0 left-0 flex justify-center items-center">
            {/* <img src={<play/>} className='h-full w-full' alt="" /> */}
            <BsFillCaretRightFill className="text-6xl text-white" />
          </div>
        </div>
        <div className="relative cursor-pointer">
          <img
            src={require("./images/image-4.png")}
            className="h-full w-full"
            alt=""
          />
          <div className="absolute bottom-5 left-5 text-white">
            <p>img 123213</p>
            <p>05 jan 2004 02:00</p>
          </div>
          <div className="w-full h-full absolute top-0 left-0 flex justify-center items-center">
            {/* <img src={<play/>} className='h-full w-full' alt="" /> */}
            <BsFillCaretRightFill className="text-6xl text-white" />
          </div>
        </div>
        <div className="relative cursor-pointer">
          <img
            src={require("./images/image-5.png")}
            className="h-full w-full"
            alt=""
          />
          <div className="absolute bottom-5 left-5 text-white">
            <p>img 123213</p>
            <p>05 jan 2004 02:00</p>
          </div>
          <div className="w-full h-full absolute top-0 left-0 flex justify-center items-center">
            {/* <img src={<play/>} className='h-full w-full' alt="" /> */}
            <BsFillCaretRightFill className="text-6xl text-white" />
          </div>
        </div>
        <div className="relative cursor-pointer">
          <img
            src={require("./images/image-6.png")}
            className="h-full w-full"
            alt=""
          />
          <div className="absolute bottom-5 left-5 text-white">
            <p>img 123213</p>
            <p>05 jan 2004 02:00</p>
          </div>
          <div className="w-full h-full absolute top-0 left-0 flex justify-center items-center">
            {/* <img src={<play/>} className='h-full w-full' alt="" /> */}
            <BsFillCaretRightFill className="text-6xl text-white" />
          </div>
        </div>
        <div className="relative cursor-pointer">
          <img
            src={require("./images/image-7.png")}
            className="h-full w-full"
            alt=""
          />
          <div className="absolute bottom-5 left-5 text-white">
            <p>img 123213</p>
            <p>05 jan 2004 02:00</p>
          </div>
          <div className="w-full h-full absolute top-0 left-0 flex justify-center items-center">
            {/* <img src={<play/>} className='h-full w-full' alt="" /> */}
            <BsFillCaretRightFill className="text-6xl text-white" />
          </div>
        </div>
        <div className="relative cursor-pointer">
          <img
            src={require("./images/image-8.png")}
            className="h-full w-full"
            alt=""
          />
          <div className="absolute bottom-5 left-5 text-white">
            <p>img 123213</p>
            <p>05 jan 2004 02:00</p>
          </div>
          <div className="w-full h-full absolute top-0 left-0 flex justify-center items-center">
            {/* <img src={<play/>} className='h-full w-full' alt="" /> */}
            <BsFillCaretRightFill className="text-6xl text-white" />
          </div>
        </div>
        <div className="relative cursor-pointer">
          <img
            src={require("./images/image-9.png")}
            className="h-full w-full"
            alt=""
          />
          <div className="absolute bottom-5 left-5 text-white">
            <p>img 123213</p>
            <p>05 jan 2004 02:00</p>
          </div>
          <div className="w-full h-full absolute top-0 left-0 flex justify-center items-center">
            {/* <img src={<play/>} className='h-full w-full' alt="" /> */}
            <BsFillCaretRightFill className="text-6xl text-white" />
          </div>
        </div>
        <div className="relative cursor-pointer">
          <img
            src={require("./images/image-1.png")}
            className="h-full w-full"
            alt=""
          />
          <div className="absolute bottom-5 left-5 text-white">
            <p>img 123213</p>
            <p>05 jan 2004 02:00</p>
          </div>
          <div className="w-full h-full absolute top-0 left-0 flex justify-center items-center">
            {/* <img src={<play/>} className='h-full w-full' alt="" /> */}
            <BsFillCaretRightFill className="text-6xl text-white" />
          </div>
        </div>
        <div className="relative cursor-pointer">
          <img
            src={require("./images/image-5.png")}
            className="h-full w-full"
            alt=""
          />
          <div className="absolute bottom-5 left-5 text-white">
            <p>img 123213</p>
            <p>05 jan 2004 02:00</p>
          </div>
          <div className="w-full h-full absolute top-0 left-0 flex justify-center items-center">
            {/* <img src={<play/>} className='h-full w-full' alt="" /> */}
            <BsFillCaretRightFill className="text-6xl text-white" />
          </div>
        </div>
        <div className="relative cursor-pointer">
          <img
            src={require("./images/image-3.png")}
            className="h-full w-full"
            alt=""
          />
          <div className="absolute bottom-5 left-5 text-white">
            <p>img 123213</p>
            <p>05 jan 2004 02:00</p>
          </div>
          <div className="w-full h-full absolute top-0 left-0 flex justify-center items-center">
            {/* <img src={<play/>} className='h-full w-full' alt="" /> */}
            <BsFillCaretRightFill className="text-6xl text-white" />
          </div>
        </div>
      </div>

    </div>
  );
};

export default VideoFile;
