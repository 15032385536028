import React from "react";
import Navbar from "./components/Navbar";

const IpLocation = () => {
  return (
    <div className=" absolute h-full w-full right-0 p-4 lg:p-4 overflow-x-auto">
      <Navbar />
      <div className="bg-white overflow-x-auto mt-8  p-5 lg:p-8 w-full rounded-2xl">
        <table className="w-full text-sm text-left text-[#2C296B] text-[16px] overflow-auto">
          <thead className="text-xs text-gray-700 uppercase bg-transparent  text-[16px]">
            <tr className="text-lbg">
              <th scope="col" className="py-1 lg:py-3 px-4  lg:px-6 text-base ">
                Remote IP
              </th>
              <th scope="col" className="py-1 lg:py-3 px-4   lg:px-6 text-base">
                Country
              </th>
              <th scope="col" className="py-1 lg:py-3 px-4   lg:px-6 text-base">
                City
              </th>
              <th scope="col" className="py-1 lg:py-3 px-4   lg:px-6 text-base">
                Date
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="bg-[#F8F8F8]">
              <td className="py-6 px-6 text-base">172.28.51.92</td>
              <td className="py-6 px-6 text-base">United States</td>
              <td className="py-6 px-6 text-base">New York</td>
              <td className="py-6 px-6 text-base">10 Jul 2014</td>
            </tr>
            <tr className="bg-[#FFF]">
              <td className="py-6 px-6 text-base">172.24.23.69</td>
              <td className="py-6 px-6 text-base">United States</td>
              <td className="py-6 px-6 text-base">Philadelphia</td>
              <td className="py-6 px-6 text-base">10 Jul 2014</td>
            </tr>
            <tr className="bg-[#F8F8F8]">
              <td className="py-6 px-6 text-base">172.24.23.69</td>
              <td className="py-6 px-6 text-base">United States</td>
              <td className="py-6 px-6 text-base">Baltimore</td>
              <td className="py-6 px-6 text-base">10 Jul 2014</td>
            </tr>
            <tr className="bg-[#FFF]">
              <td className="py-6 px-6 text-base">172.28.51.92</td>
              <td className="py-6 px-6 text-base">United States</td>
              <td className="py-6 px-6 text-base">Washington</td>
              <td className="py-6 px-6 text-base">10 Jul 2014</td>
            </tr>
            <tr className="bg-[#F8F8F8]">
              <td className="py-6 px-6 text-base">172.24.23.69</td>
              <td className="py-6 px-6 text-base">United States</td>
              <td className="py-6 px-6 text-base">Raleigh</td>
              <td className="py-6 px-6 text-base">10 Jul 2014</td>
            </tr>
            <tr className="bg-[#FFF]">
              <td className="py-6 px-6 text-base">172.28.51.92</td>
              <td className="py-6 px-6 text-base">United States</td>
              <td className="py-6 px-6 text-base">Philadelphia</td>
              <td className="py-6 px-6 text-base">05 Jul 2014</td>
            </tr>
            <tr className="bg-[#F8F8F8]">
              <td className="py-6 px-6 text-base">172.24.23.69</td>
              <td className="py-6 px-6 text-base">United States</td>
              <td className="py-6 px-6 text-base">Washington</td>
              <td className="py-6 px-6 text-base">05 Jul 2014</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default IpLocation;
