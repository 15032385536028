import React from "react";
import Navbar from "./components/Navbar";
const Account = () => {
  return (
    <div className=" absolute h-full w-full right-0 p-4 lg:p-4 overflow-x-auto">
      <Navbar />
      <div className="overflow-x-auto w-full">
        {/* <div className="h-auto mt-8 grid lg:grid-cols-2 md:grid-cols-2 gap-3 lg:gap-4"> */}
          <div className=" mt-8 h-auto w-auto rounded-2xl grid grid-cols-1 gap-3 lg:grid-cols-2">
            <div className="bg-white px-5 py-5 rounded-2xl flex justify-between flex-col lg:flex-row">
              <div className="text-[#2C294B] flex items-center gap-[1rem]">
                {""}
                <img
                  src={require("./icons/facebook.svg").default}
                  alt="adfsfs"
                  className="h-[36px]"
                />{" "}
                <div className="flex flex-col">
                  <h2 className="text-[20px] font-bold">facebook</h2>
                  <p className="text-[16px]">com.facebook.katana</p>
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <p className="text-[#ADB0BE]">21 Sep 2014 13:59</p>
                <button className="bg-[#009EF7] hover:bg-blue-600 text-white py-2 px-4 rounded">
                Open the website
              </button>
              </div>
            </div>

            <div className="bg-white px-5 py-5 rounded-2xl flex justify-between flex-col lg:flex-row">
              <div className="text-[#2C294B] flex items-center gap-[1rem]">
                {""}
                <img
                  src={require("./icons/whatsapp.svg").default}
                  alt="adfsfs"
                  className="h-[36px]"
                />{" "}
                <div className="flex flex-col">
                  <h2 className="text-[20px] font-bold">facebook</h2>
                  <p className="text-[16px]">com.facebook.katana</p>
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <p className="text-[#ADB0BE]">21 Sep 2014 13:59</p>
                <button className="bg-[#009EF7] hover:bg-blue-600 text-white py-2 px-4 rounded">
                Open the website
              </button>
              </div>
            </div>

            <div className="bg-white px-5 py-5 rounded-2xl flex justify-between flex-col lg:flex-row">
              <div className="text-[#2C294B] flex items-center gap-[1rem]">
                {""}
                <img
                  src={require("./icons/instagram.svg").default}
                  alt="adfsfs"
                  className="h-[36px]"
                />{" "}
                <div className="flex flex-col">
                  <h2 className="text-[20px] font-bold">facebook</h2>
                  <p className="text-[16px]">com.facebook.katana</p>
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <p className="text-[#ADB0BE]">21 Sep 2014 13:59</p>
                <button className="bg-[#009EF7] hover:bg-blue-600 text-white py-2 px-4 rounded">
                Open the website
              </button>
              </div>
            </div>

            <div className="bg-white px-5 py-5 rounded-2xl flex justify-between flex-col lg:flex-row">
              <div className="text-[#2C294B] flex items-center gap-[1rem]">
                {""}
                <img
                  src={require("./icons/snapchat.svg").default}
                  alt="adfsfs"
                  className="h-[36px]"
                />{" "}
                <div className="flex flex-col">
                  <h2 className="text-[20px] font-bold">facebook</h2>
                  <p className="text-[16px]">com.facebook.katana</p>
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <p className="text-[#ADB0BE]">21 Sep 2014 13:59</p>
                <button className="bg-[#009EF7] hover:bg-blue-600 text-white py-2 px-4 rounded">
                Open the website
              </button>
              </div>
            </div>
          </div>
        {/* </div> */}
      </div>
    </div>
  );
};

export default Account;
