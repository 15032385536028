import React from "react";
import Navbar from "./components/Navbar";
const Emails = () => {
  return (
    <div className=" absolute h-full w-full right-0 p-4 lg:p-4 overflow-x-auto">
      <Navbar />
      <div className="bg-white overflow-x-auto mt-8  p-5 lg:p-8 w-full rounded-2xl">
        <table className="w-full text-sm text-left text-[#2C296B] text-[16px] overflow-auto">
          <tbody>
            <tr className="bg-[#F8F8F8]">
              <td className="py-6 px-6 text-base">mariah@example.com</td>
              <td className="py-6 px-6 text-base">
                <span className="text-[#009EF7]">Your order status:</span>Lorem ipsum
                dolor sit amet, consetetur sadipscing elitr, sed diam nony
                eirmod tem...
              </td>
              <td className="py-6 px-6 text-base">19:11</td>
            </tr>
            <tr className="bg-[#FFF]">
              <td className="py-6 px-6 text-base">mariah@example.com</td>
              <td className="py-6 px-6 text-base">
                <span className="text-[#009EF7]">Your order status:</span>Lorem ipsum
                dolor sit amet, consetetur sadipscing elitr, sed diam nony
                eirmod tem...
              </td>
              <td className="py-6 px-6 text-base">19:11</td>
            </tr>
            <tr className="bg-[#F8F8F8]">
              <td className="py-6 px-6 text-base">mariah@example.com</td>
              <td className="py-6 px-6 text-base">
                <span className="text-[#009EF7]">Your order status:</span>Lorem ipsum
                dolor sit amet, consetetur sadipscing elitr, sed diam nony
                eirmod tem...
              </td>
              <td className="py-6 px-6 text-base">19:11</td>
            </tr>
            <tr className="bg-[#FFF]">
              <td className="py-6 px-6 text-base">mariah@example.com</td>
              <td className="py-6 px-6 text-base">
                <span className="text-[#009EF7]">Your order status:</span>Lorem ipsum
                dolor sit amet, consetetur sadipscing elitr, sed diam nony
                eirmod tem...
              </td>
              <td className="py-6 px-6 text-base">19:11</td>
            </tr>
            <tr className="bg-[#F8F8F8]">
              <td className="py-6 px-6 text-base">mariah@example.com</td>
              <td className="py-6 px-6 text-base">
                <span className="text-[#009EF7]">Your order status:</span>Lorem ipsum
                dolor sit amet, consetetur sadipscing elitr, sed diam nony
                eirmod tem...
              </td>
              <td className="py-6 px-6 text-base">19:11</td>
            </tr>
            <tr className="bg-[#FFF]">
              <td className="py-6 px-6 text-base">mariah@example.com</td>
              <td className="py-6 px-6 text-base">
                <span className="text-[#009EF7]">Your order status:</span>Lorem ipsum
                dolor sit amet, consetetur sadipscing elitr, sed diam nony
                eirmod tem...
              </td>
              <td className="py-6 px-6 text-base">19:11</td>
            </tr>
            <tr className="bg-[#F8F8F8]">
              <td className="py-6 px-6 text-base">mariah@example.com</td>
              <td className="py-6 px-6 text-base">
                <span className="text-[#009EF7]">Your order status:</span>Lorem ipsum
                dolor sit amet, consetetur sadipscing elitr, sed diam nony
                eirmod tem...
              </td>
              <td className="py-6 px-6 text-base">19:11</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Emails;
