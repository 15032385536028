import React from "react";
import Navbar from "./components/Navbar";
const GpsLocation = () => {
  return (
    <div className=" absolute h-full w-full right-0 p-4 lg:p-4 overflow-x-auto">
      <Navbar />
      <div className="bg-white mt-8 overflow-x-auto  w-full rounded-2xl">
        <iframe
          title='map'
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d128975.96953806686!2d-80.14054635209651!3d25.801845790294525!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d9b3e287b4ee11%3A0xa825c25a07fb0a24!2s555%20Gate%20Ln%2C%20Miami%2C%20FL%2033137%2C%20USA!5e0!3m2!1sen!2sin!4v1670668671027!5m2!1sen!2sin"
          width="600"
          height="450"
          style={{ border: 0 }}
          allowfullscreen=""
          loading="lazy"
          className="w-full h-[561px]"
          referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>

      <div className="mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
        <div className="text-pcolor p-5 bg-white rounded-2xl">
          <p className="text-[#ADB0BE] text-[20px]">Position at</p>
          <p className="text-[30px] font-bold">24 Feb 2020</p>
        </div>
        <div className="text-pcolor p-5 bg-white rounded-2xl">
          <p className="text-[#ADB0BE] text-[20px]">Latitude</p>
          <p className="text-[30px] font-bold">25.817416</p>
        </div>
        <div className="text-pcolor p-5 bg-white rounded-2xl">
          <p className="text-[#ADB0BE] text-[20px]">Longitude</p>
          <p className="text-[30px] font-bold">80.187005</p>
        </div>
        <div className="text-pcolor p-5 bg-white rounded-2xl">
          <p className="text-[#ADB0BE] text-[20px]">Altitude</p>
          <p className="text-[30px] font-bold">17</p>
        </div>
        <div className="text-pcolor p-5 bg-white rounded-2xl">
          <p className="text-[#ADB0BE] text-[20px]">Speed: (m/s)</p>
          <p className="text-[30px] font-bold">49</p>
        </div>
        <div className="text-pcolor p-5 bg-white rounded-2xl">
          <p className="text-[#ADB0BE] text-[20px]">Type</p>
          <p className="text-[30px] font-bold">GPS</p>
        </div>
      </div>
    </div>
  );
};
export default GpsLocation;
