import React from "react";
import { useState } from "react";
import { NavLink  } from "react-router-dom";

const Navbar = () => {
  let [isAccount, setAccount] = useState(0);
  let [isBell, setBell] = useState(0);
  let [isMessage, setMessage] = useState(0);

  const ProfileHandleClick = () => {
    setAccount((current) => !current);
    setBell(0);
    setMessage(0);
  };

  const BellHandleClick = () => {
    setBell((current) => !current);
    setAccount(0);
    setMessage(0);
  };

  const MessageHandleClick = () => {
    setMessage((current) => !current);
    setAccount(0);
    setBell(0);
  };

  
  // let location = window.location.pathname.split('/');
  
  let navigationName = () => {
    let location = window.location.pathname;
    if(location === '/deviceinfo'){
      return 'Device Info'
    }else if(location === '/callhistory'){
      return 'Call History'
    }else if(location === '/smshistory'){
      return 'SMS History'
    }else if(location === '/addressBook'){
      return 'Address Book'
    }else if(location === '/gpslocation'){
      return 'GPS Location'
    }else if(location === '/iplocation'){
      return 'IP Location'
    }else if(location === '/images'){
      return 'Images'
    }else if(location === '/videofile'){
      return 'Video Files'
    }else if(location === '/audiofile'){
      return 'Audio File'
    }else if(location === '/documents'){
      return 'Documents'
    }else if(location === '/browserhistory'){
      return 'Browser History'
    }else if(location === '/browserbookmarkhistory'){
      return 'Browser Bookmark History'
    }else if(location === '/tellegrammessages'){
      return 'Social Apps'
    }else if(location === '/applications'){
      return 'Applications'
    }else if(location === '/calendar'){
      return 'Calendar'
    }else if(location === '/wifinetwork'){
      return 'Wifi Network'
    }else if(location === '/keylogger'){
      return 'Key Logger'
    }else if(location === '/clipboardlogs'){
      return 'Clipboard Logs'
    }else if(location === '/simchanges'){
      return 'Sim Changes'
    }else if(location === '/account'){
      return 'Account'
    }else if(location === '/emails'){
      return 'Email History'
    }else if(location === '/dataexport'){
      return 'Data Export'
    }else{
      return 'Dashboard';
    }
  }
  
  return (
    <div className="flex justify-between align-middle flex-col-reverse px-1 py-3 lg:flex-row">
      <div className="mt-8">
        <h1 className="text-white text-2xl font-medium capitalize">{navigationName()}</h1>
        <p className="text-white font-sm">Device info: Sumsung s22 Ultra</p>
      </div>

      <div className="flex justify-center items-center flex-col lg:flex-row">
        <div className="mb-5 lg:mb-0">
          <input
            type="text"
            placeholder="Search for data and reports"
            className="text-white p-3 w-80 bg-[#403e5d] outline-none border-none rounded-md placeholder:text-[#859ca9]"
          />
        </div>

        <div className="flex justify-between items-center w-full">
          <div className="flex justify-center align-middle text-white">
            {/* bell icone */}
            <div
              className="bg-[#403e5d] ml-2 rounded-full cursor-pointer relative"
              onClick={BellHandleClick}>
              <img
                src={require("../icons/navbar/bell.svg").default}
                className="w-7 h-7 m-2 "
                alt=""
              />
              {/* bell popup */}
              <div
                className={
                  isBell
                    ? "profiledpd active overflow-hidden absolute top-[70px] left-[0px] w-[293px] h-auto bg-white rounded-2xl border-b-[1px] z-40 bell"
                    : "profiledpd overflow-hidden absolute top-[70px] left-[0px] w-[293px] h-auto bg-white rounded-2xl border-b-[1px]"
                }>
                <div className=" border-[#f2f2f2] border-b-[1px] py-3 px-5 hover:bg-mainbg">
                  <span className="text-lbg">3 Unread Notifications</span>
                </div>
                <div className=" border-[#f2f2f2] border-b-[1px] py-3 flex justify-start items-center px-5 hover:bg-mainbg">
                  {/* <div className="m-2 bg-[#f2f2f2] rounded-full"> */}
                  <img
                    src={require("../icons/navbar/bell/mail.svg").default}
                    className="p-2 bg-[#f7f7f7] rounded-full mr-4"
                    alt="account"
                  />
                  {/* </div> */}

                  <div className="leading-4">
                    <p className="text-pcolor text-[16px]">
                      You got a email notification
                    </p>
                    <p className="text-[#ADB0BE] text-sm">
                      April 12, 2018 06:50
                    </p>
                  </div>
                </div>
                <div className=" border-[#f2f2f2] border-b-[1px] py-3 flex justify-start items-center px-5 hover:bg-mainbg">
                  {/* <div className="m-2 bg-[#f2f2f2] rounded-full"> */}
                  <img
                    src={require("../icons/navbar/bell/phone.svg").default}
                    className="p-2 bg-[#f7f7f7] rounded-full mr-4"
                    alt="account"
                  />
                  {/* </div> */}

                  <div className="leading-4">
                    <p className="text-pcolor text-[16px]">
                      You got a email notification
                    </p>
                    <p className="text-[#ADB0BE] text-sm">
                      April 12, 2018 06:50
                    </p>
                  </div>
                </div>
                <div className=" border-[#f2f2f2] border-b-[1px] py-3 flex justify-start items-center px-5 hover:bg-mainbg">
                  {/* <div className="m-2 bg-[#f2f2f2] rounded-full"> */}
                  <img
                    src={require("../icons/navbar/bell/phone.svg").default}
                    className="p-2 bg-[#f7f7f7] rounded-full mr-4"
                    alt="account"
                  />
                  {/* </div> */}

                  <div className="leading-4">
                    <p className="text-pcolor text-[16px]">
                      You got a email notification
                    </p>
                    <p className="text-[#ADB0BE] text-sm">
                      April 12, 2018 06:50
                    </p>
                  </div>
                </div>

                <div className="py-3 px-5 bg-[#f2f2f2]">
                  <span className="text-lbg flex justify-center items-center w-full">
                    All notifications
                  </span>
                </div>
              </div>
            </div>
            {/* message */}
            <div
              className="bg-[#403e5d] ml-2 rounded-full cursor-pointer relative"
              onClick={MessageHandleClick}>
              <img
                src={require("../icons/navbar/message.svg").default}
                className="w-7 h-7 m-2 "
                alt=""
              />
              {/* message popup */}
              <div
                className={
                  isMessage
                    ? "profiledpd active overflow-hidden absolute top-[70px] left-[-70px] w-[293px] h-auto bg-white rounded-2xl border-b-[1px] z-40"
                    : "profiledpd overflow-hidden absolute top-[70px] left-[-70px] w-[293px] h-auto bg-white rounded-2xl border-b-[1px]"
                }>
                <div className=" border-[#f2f2f2] border-b-[1px] py-3 px-5 hover:bg-mainbg">
                  <span className="text-lbg">3 Unread Messages</span>
                </div>
                <div className=" border-[#f2f2f2] border-b-[1px] py-3 flex justify-start items-center px-5 hover:bg-mainbg">
                  {/* <div className="m-2 bg-[#f2f2f2] rounded-full"> */}
                  <img
                    src={
                      require("../icons/navbar/message/profile-1.svg").default
                    }
                    className="bg-[#f7f7f7] rounded-full mr-4"
                    alt="account"
                  />
                  {/* </div> */}

                  <div className="leading-4">
                    <p className="text-pcolor text-[16px]">
                      You got a email notification
                    </p>
                    <p className="text-[#ADB0BE] text-sm">
                      April 12, 2018 06:50
                    </p>
                  </div>
                </div>
                <div className=" border-[#f2f2f2] border-b-[1px] py-3 flex justify-start items-center px-5 hover:bg-mainbg">
                  {/* <div className="m-2 bg-[#f2f2f2] rounded-full"> */}
                  <img
                    src={
                      require("../icons/navbar/message/profile-2.svg").default
                    }
                    className="bg-[#f7f7f7] rounded-full mr-4"
                    alt="account"
                  />
                  {/* </div> */}

                  <div className="leading-4">
                    <p className="text-pcolor text-[16px]">
                      You got a email notification
                    </p>
                    <p className="text-[#ADB0BE] text-sm">
                      April 12, 2018 06:50
                    </p>
                  </div>
                </div>
                <div className=" border-[#f2f2f2] border-b-[1px] py-3 flex justify-start items-center px-5 hover:bg-mainbg">
                  {/* <div className="m-2 bg-[#f2f2f2] rounded-full"> */}
                  <img
                    src={
                      require("../icons/navbar/message/profile-3.svg").default
                    }
                    className="bg-[#f7f7f7] rounded-full mr-4"
                    alt="account"
                  />
                  {/* </div> */}

                  <div className="leading-4">
                    <p className="text-pcolor text-[16px]">
                      You got a email notification
                    </p>
                    <p className="text-[#ADB0BE] text-sm">
                      April 12, 2018 06:50
                    </p>
                  </div>
                </div>

                <div className="py-3 px-5 bg-[#f2f2f2]">
                  <span className="text-lbg flex justify-center items-center w-full">
                    All Message
                  </span>
                </div>
              </div>
            </div>
            <div className="bg-[#403e5d] ml-2 rounded-full cursor-pointer">
              <img
                src={require("../icons/navbar/email.svg").default}
                className="w-7 h-7 m-2 "
                alt=""
              />
            </div>
          </div>

          <div className="ml-4">
            <div
              onClick={ProfileHandleClick}
              className="cursor-pointer bg-lbg relative flex justify-center align-bottom p-1 rounded-full">
              <div className="bg-[#403e5d] rounded-full w-9 h-9">
              <img src={ require('../images/avatar.svg').default } alt="" />
              </div>
              <div className="text-white leading-4 pl-2 pr-8">
                <h3 className="text-[14px]">Etan Castro</h3>
                <p className="text-[11px]">View profile</p>
              </div>
              <div className="tri"></div>
              <div
                className={
                  isAccount
                    ? "profiledpd active overflow-hidden absolute top-[70px] right-0 w-[293px] h-auto bg-white rounded-2xl border-b-[1px] z-40"
                    : "profiledpd overflow-hidden absolute top-[70px] right-0 w-[293px] h-auto bg-white rounded-2xl border-b-[1px]"
                }>
                <div className=" border-[#f2f2f2] border-b-[1px] py-3 px-5 hover:bg-mainbg">
                  <img
                    src={require("../icons/navbar/account/account.svg").default}
                    className="inline-block mr-[9px]"
                    alt="account"
                  />
                  <span>Account</span>
                </div>
                <div className=" border-[#f2f2f2] border-b-[1px] py-3 px-5 hover:bg-mainbg">
                  <img
                    src={
                      require("../icons/navbar/account/settings.svg").default
                    }
                    className="inline-block mr-[9px]"
                    alt="account"
                  />
                  <span>Settings</span>
                </div>
                <NavLink
                  to="/billing"
                  className="text-lg"
                  activeclassname="active">
                  
                  
                <div className=" border-[#f2f2f2] border-b-[1px] py-3 px-5 hover:bg-mainbg">
                  <img
                    src={require("../icons/navbar/account/billing.svg").default}
                    className="inline-block mr-[9px]"
                    alt="account"
                  />
                  <span>Billing</span>
                </div>
                </NavLink>

                <div className="py-3 px-5 bg-[#f2f2f2]">
                  <img
                    src={require("../icons/navbar/account/logout.svg").default}
                    className="inline-block mr-[9px]"
                    alt="account"
                  />
                  <span>Logout</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
