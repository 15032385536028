import React from "react";
import Navbar from "./components/Navbar";

const CallHistory = () => {
  return (
    <div className=" absolute h-full w-full right-0 pb-8 p-4 lg:p-4 overflow-x-auto">
      <Navbar />
      <div className="bg-white overflow-x-auto mt-8  p-5 lg:p-8 w-full rounded-2xl">
      
          {/* </table> */}

        <table className="w-full text-sm text-left text-[#2C296B] text-[16px] overflow-auto">
          <thead className="text-gray-700 uppercase bg-transparent  text-[16px]">
            <tr className="text-lbg text-[16px]">
              <th scope="col" className="py-1 lg:py-3 px-4   lg:px-6 text-base ">
                Types
              </th>
              <th scope="col" className="py-1 lg:py-3 px-4   lg:px-6 text-base ">
                Person
              </th>
              <th scope="col" className="py-1 lg:py-3 px-4   lg:px-6 text-base ">
                Number
              </th>
              <th scope="col" className="py-1 lg:py-3 px-4   lg:px-6 text-base ">
                Duration
              </th>
              <th scope="col" className="py-1 lg:py-3 px-4   lg:px-6 text-base ">
                Date & Time
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="bg-[#F8F8F8]">
              <td className="flex  justify-start items-center text-[16px] px-6 py-6">
                {" "}
                <img
                  src={
                    require("./icons/phone-pause.svg").default
                  }
                  className="inline-block mr-[10px]"
                  alt=""
                />{" "}
                <td className="">Incoming</td>
              </td>
              <td className="py-6 px-6 text-base ">Jessica Cox Anderson</td>
              <td className="py-6 px-6 text-base ">+1 (702) 652-5559</td>
              <td className="py-6 px-6 text-base ">6 min. 27 sec</td>
              <td className="py-6 px-6 text-base ">22 Jan 2016 23:23</td>
            </tr>
            <tr className="bg-transparent">
              <td className="flex  justify-start items-center text-[16px] px-6 py-6">
                {" "}
                <img
                  src={
                    require("./icons/out.svg").default
                  }
                  className="inline-block mr-[10px]"
                  alt=""
                />{" "}
                <td>Outgoing</td>
              </td>
              <td className="py-6 px-6 text-base ">Jessica Cox Anderson</td>
              <td className="py-6 px-6 text-base ">+1 (702) 652-5559</td>
              <td className="py-6 px-6 text-base ">6 min. 27 sec</td>
              <td className="py-6 px-6 text-base ">22 Jan 2016 23:23</td>
            </tr>
            <tr className="bg-[#F8F8F8]">
              <td className="flex  justify-start items-center text-[16px] px-6 py-6">
                {" "}
                <img
                  src={
                    require("./icons/miss.svg").default
                  }
                  className="inline-block mr-[10px]"
                  alt=""
                />{" "}
                <td className="">Missed</td>
              </td>
              <td className="py-6 px-6 text-base ">Jessica Cox Anderson</td>
              <td className="py-6 px-6 text-base ">+1 (702) 652-5559</td>
              <td className="py-6 px-6 text-base ">6 min. 27 sec</td>
              <td className="py-6 px-6 text-base ">22 Jan 2016 23:23</td>
            </tr>
            <tr className="bg-transparent">
              <td className="flex  justify-start items-center text-[16px] px-6 py-6">
                {" "}
                <img
                  src={
                    require("./icons/phone-pause.svg").default
                  }
                  className="inline-block mr-[10px]"
                  alt=""
                />{" "}
                <td>Incoming</td>
              </td>
              <td className="py-6 px-6 text-base ">Jessica Cox Anderson</td>
              <td className="py-6 px-6 text-base ">+1 (702) 652-5559</td>
              <td className="py-6 px-6 text-base ">6 min. 27 sec</td>
              <td className="py-6 px-6 text-base ">22 Jan 2016 23:23</td>
            </tr>
            <tr className="bg-[#F8F8F8]">
              <td className="flex  justify-start items-center text-[16px] px-6 py-6">
                {" "}
                <img
                  src={
                    require("./icons/miss.svg").default
                  }
                  className="inline-block mr-[10px]"
                  alt=""
                />{" "}
                <td className="">Missed</td>
              </td>
              <td className="py-6 px-6 text-base ">Jessica Cox Anderson</td>
              <td className="py-6 px-6 text-base ">+1 (702) 652-5559</td>
              <td className="py-6 px-6 text-base ">6 min. 27 sec</td>
              <td className="py-6 px-6 text-base ">22 Jan 2016 23:23</td>
            </tr>
           
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CallHistory;
