import { Route, Routes } from "react-router-dom";
import "./App.css";
import CallHistory from "./CallHistory";
import Smshistory from "./Smshistory";
import AddressBook from "./AddressBook";
import Images from "./Images";
import TellegramMessages from "./TellegramMessages";
import Documents from "./Documents";
import BrowserHistory from "./BrowserHistory";
import BrowserBookmarkHistory from "./BrowserBookmarkHistory";
import WifiNetwork from "./WifiNetwork";
import Calendar from "./Calendar";
import KeyLogger from "./KeyLogger";
import SimChanges from "./SimChanges";
import ClipBoardLogs from "./ClipBoardLogs";
import Applications from "./Applications";
import Account from "./Account";
import DeviceInfo from './DeviceInfo'
import VideoFile from "./VideoFile";
import AudioFile from "./AudioFile";
import DataExport from "./DataExport";
import GpsLocation from "./GpsLocation";
import IpLocation from "./IpLocation";
import Billing from "./Billing";
import Emails from "./Emails";
function App() {

  return (

    <div className="mainback h-screen w-screen relative bg-mainbg z-[3]">
      <Routes>
        <Route exact path="/" element={<DeviceInfo />} />
        <Route path="/deviceinfo" element={<DeviceInfo />} />
        <Route path="/callhistory" element={<CallHistory />} />
        <Route path="/smshistory" element={<Smshistory />} />
        <Route path="/simchanges" element={<SimChanges />} />
        <Route path="/images" element={<Images />} />
        <Route path="/tellegrammessages" element={<TellegramMessages />} />
        <Route path="/documents" element={<Documents />} />
        <Route path="/browserhistory" element={<BrowserHistory />} />
        <Route path="/browserbookmarkhistory" element={<BrowserBookmarkHistory />} />
        <Route path="/wifinetwork" element={<WifiNetwork />} />
        <Route path="/calendar" element={<Calendar />} />
        <Route path="/keylogger" element={<KeyLogger />} />
        <Route path="/clipboardlogs" element={<ClipBoardLogs />} />
        <Route path="/applications" element={<Applications />} />
        <Route path="/videofile" element={<VideoFile/>} />
        <Route path="/account" element={<Account/>} />
        <Route path="/audiofile" element={<AudioFile/>} />
        <Route path="/dataexport" element={<DataExport/>} />
        <Route path="/gpslocation" element={<GpsLocation/>} />
        <Route path="/addressbook" element={<AddressBook/>} />
        <Route path="/iplocation" element={<IpLocation/>} />
        <Route path="/billing" element={<Billing/>} />
        <Route path="/emails" element={<Emails/>} />
      </Routes>
    </div>
  );
}

export default App;
