import React from "react";
import Navbar from "./components/Navbar";

const AddressBook = () => {
  return (
    <div className=" absolute h-full w-full right-0 p-4 lg:p-4 overflow-x-auto">
      <Navbar />
      <div className="bg-white mt-8 overflow-x-auto  p-5 lg:p-8 w-full rounded-2xl">
        <table className="w-full text-sm text-left text-[#2C296B] text-[16px] overflow-auto">
          <thead className="text-xs text-gray-700 bg-transparent text-[16px]">
            <tr className="text-lbg">
              <th scope="col" className="py-1 lg:py-3 px-4  lg:px-6 text-base">
                Display Name
              </th>
              <th scope="col" className="py-1 lg:py-3 px-4  lg:px-6 text-base">
                Phone
              </th>
              <th scope="col" className="py-1 lg:py-3 px-4  lg:px-6 text-base">
                Calls
              </th>
              <th scope="col" className="py-1 lg:py-3 px-4  lg:px-6 text-base">
                SMS
              </th>
            </tr>
          </thead>
          <tbody className="text-base">
            <tr className="bg-[#F8F8F8]">
              <td className="py-6 px-7">Jessica Cox Anderson</td>
              <td className="py-6 px-7 row-span-2">+1 (702) 452-5559</td>
              <td className="py-6 px-7">45</td>
              <td className="py-6 px-7">23</td>
            </tr>
            <tr className="bg-transparent">
              <td className="py-6 px-7">Angela Garcia Carterv</td>
              <td className="py-6 px-7">+1 (702) 452-5559</td>
              <td className="py-6 px-7">1</td>
              <td className="py-6 px-7">123</td>
            </tr>
            <tr className="bg-[#F8F8F8]">
              <td className="py-6 px-7">Lisa Bell Collins</td>
              <td className="py-6 px-7 row-span-2">+1 (702) 452-5559</td>
              <td className="py-6 px-7">23</td>
              <td className="py-6 px-7">43</td>
            </tr>
            <tr className="bg-transparent">
              <td className="py-6 px-7">Susan Mitchell Brown</td>
              <td className="py-6 px-7">+1 (702) 452-5559</td>
              <td className="py-6 px-7">32</td>
              <td className="py-6 px-7">123</td>
            </tr>
            <tr className="bg-[#F8F8F8]">
              <td className="py-6 px-7">Susan Parker James</td>
              <td className="py-6 px-7 row-span-2">+1 (702) 452-5559</td>
              <td className="py-6 px-7">43</td>
              <td className="py-6 px-7">32</td>
            </tr>
            <tr className="bg-transparent">
              <td className="py-6 px-7">Sarah Brown Lewis</td>
              <td className="py-6 px-7">+1 (702) 452-5559</td>
              <td className="py-6 px-7">12</td>
              <td className="py-6 px-7">234</td>
            </tr>
            <tr className="bg-[#F8F8F8]">
              <td className="py-6 px-7">Sarah Peterson Edwards</td>
              <td className="py-6 px-7 row-span-2">+1 (702) 452-5559</td>
              <td className="py-6 px-7">12</td>
              <td className="py-6 px-7">234</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default AddressBook;
