import React from 'react'
import Navbar from "./components/Navbar";

const SimChanges = () => {
  return (
    <div className=" absolute h-full w-full right-0 p-4 lg:p-4 overflow-x-auto">
    <Navbar />
    <div className="bg-white mt-8 overflow-x-auto  p-5 lg:p-8 w-full rounded-2xl">
      <table className="w-full text-sm text-left text-[#2C294B] text-[16px]">
        <thead className="text-xs text-gray-700 bg-transparent text-[16px]">
          <tr className="text-lbg text-[16px] font-semibold">
            <th scope="col" className="py-1 lg:py-3 px-4  lg:px-6 text-base">
            SIM serial
            </th>
            <th scope="col" className="py-1 lg:py-3 px-4  lg:px-6 text-base">
            Network name
            </th>
            <th scope="col" className="py-1 lg:py-3 px-4  lg:px-6 text-base">
            Network country
            </th>
            <th scope="col" className="py-1 lg:py-3 px-4  lg:px-6 text-base">
            Operator identifier
            </th>
            <th scope="col" className="py-1 lg:py-3 px-4  lg:px-6 text-base">
            Date
            </th>
          </tr>
        </thead>
        <tbody className="text-base">
          <tr className="bg-[#F8F8F8]">
            <td className="py-6 px-7">
            8932302130490236160
            </td>
            <td className="py-6 px-7">AT-AT</td>
            <td className="py-6 px-7">US</td>
            <td className="py-6 px-7">7774</td>
            <td className="py-6 px-7">18 Nov 2017 14:58</td>
          </tr>
          <tr className="bg-[#FFF]">
            <td className="py-6 px-7">
            8932302130490236160
            </td>
            <td className="py-6 px-7">AT-AT</td>
            <td className="py-6 px-7">US</td>
            <td className="py-6 px-7">7774</td>
            <td className="py-6 px-7">18 Nov 2017 14:58</td>
          </tr>
          <tr className="bg-[#F8F8F8]">
            <td className="py-6 px-7">
            8932302130490236160
            </td>
            <td className="py-6 px-7">AT-AT</td>
            <td className="py-6 px-7">US</td>
            <td className="py-6 px-7">7774</td>
            <td className="py-6 px-7">18 Nov 2017 14:58</td>
          </tr>
          <tr className="bg-[#FFF]">
            <td className="py-6 px-7">
            8932302130490236160
            </td>
            <td className="py-6 px-7">AT-AT</td>
            <td className="py-6 px-7">US</td>
            <td className="py-6 px-7">7774</td>
            <td className="py-6 px-7">18 Nov 2017 14:58</td>
          </tr>
          <tr className="bg-[#F8F8F8]">
            <td className="py-6 px-7">
            8932302130490236160
            </td>
            <td className="py-6 px-7">AT-AT</td>
            <td className="py-6 px-7">US</td>
            <td className="py-6 px-7">7774</td>
            <td className="py-6 px-7">18 Nov 2017 14:58</td>
          </tr>
          <tr className="bg-[#FFF]">
            <td className="py-6 px-7">
            8932302130490236160
            </td>
            <td className="py-6 px-7">AT-AT</td>
            <td className="py-6 px-7">US</td>
            <td className="py-6 px-7">7774</td>
            <td className="py-6 px-7">18 Nov 2017 14:58</td>
          </tr>
          <tr className="bg-[#F8F8F8]">
            <td className="py-6 px-7">
            8932302130490236160
            </td>
            <td className="py-6 px-7">AT-AT</td>
            <td className="py-6 px-7">US</td>
            <td className="py-6 px-7">7774</td>
            <td className="py-6 px-7">18 Nov 2017 14:58</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  )
}

export default SimChanges
