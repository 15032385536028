import React from "react";
import Navbar from "./components/Navbar";

const Documents = () => {
  return (
    <div className=" absolute h-full w-full right-0 p-4 lg:p-4 overflow-x-auto">
      <Navbar />
      <div className="overflow-x-auto w-full">
        <div className="h-auto mt-8 grid lg:grid-cols-4 md:grid-cols-2 gap-3 lg:gap-4">
          <div className="bg-white px-10 py-8 h-auto w-auto rounded-2xl flex flex-col lg:gap-1">
            <div className="text-[#2C294B] flex flex-col gap-3">
              <div className="flex justify-between">
                {""}<img src={require("./icons/documenticon.svg").default} alt="adfsfs" />{" "}
                <button className="bg-[#009EF7] hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
                  Download
                </button>
              </div>
              <div className="flex justify-between flex-col">
                <h2 className="text-[20px] font-bold">Demo.pdf</h2>
                <p className="text-[16px]">03:03pm 12 Oct, 2002</p>
              </div>
            </div>
          </div>
          <div className="bg-white px-10 py-8 h-auto w-auto rounded-2xl flex flex-col lg:gap-1">
            <div className="text-[#2C294B] flex flex-col gap-3">
              <div className="flex justify-between">
                {""}<img src={require("./icons/documenticon.svg").default} alt="adfsfs" />{" "}
                <button className="bg-[#009EF7] hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
                  Download
                </button>
              </div>
              <div className="flex justify-between flex-col">
                <h2 className="text-[20px] font-bold">Demo.pdf</h2>
                <p className="text-[16px]">03:03pm 12 Oct, 2002</p>
              </div>
            </div>
          </div>
          <div className="bg-white px-10 py-8 h-auto w-auto rounded-2xl flex flex-col lg:gap-1">
            <div className="text-[#2C294B] flex flex-col gap-3">
              <div className="flex justify-between">
                {""}<img src={require("./icons/documenticon.svg").default} alt="adfsfs" />{" "}
                <button className="bg-[#009EF7] hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
                  Download
                </button>
              </div>
              <div className="flex justify-between flex-col">
                <h2 className="text-[20px] font-bold">Demo.pdf</h2>
                <p className="text-[16px]">03:03pm 12 Oct, 2002</p>
              </div>
            </div>
          </div>
          <div className="bg-white px-10 py-8 h-auto w-auto rounded-2xl flex flex-col lg:gap-1">
            <div className="text-[#2C294B] flex flex-col gap-3">
              <div className="flex justify-between">
                {""}<img src={require("./icons/documenticon.svg").default} alt="adfsfs" />{" "}
                <button className="bg-[#009EF7] hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
                  Download
                </button>
              </div>
              <div className="flex justify-between flex-col">
                <h2 className="text-[20px] font-bold">Demo.pdf</h2>
                <p className="text-[16px]">03:03pm 12 Oct, 2002</p>
              </div>
            </div>
          </div>
          <div className="bg-white px-10 py-8 h-auto w-auto rounded-2xl flex flex-col lg:gap-1">
            <div className="text-[#2C294B] flex flex-col gap-3">
              <div className="flex justify-between">
                {""}<img src={require("./icons/documenticon.svg").default} alt="adfsfs" />{" "}
                <button className="bg-[#009EF7] hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
                  Download
                </button>
              </div>
              <div className="flex justify-between flex-col">
                <h2 className="text-[20px] font-bold">Demo.pdf</h2>
                <p className="text-[16px]">03:03pm 12 Oct, 2002</p>
              </div>
            </div>
          </div>
          <div className="bg-white px-10 py-8 h-auto w-auto rounded-2xl flex flex-col lg:gap-1">
            <div className="text-[#2C294B] flex flex-col gap-3">
              <div className="flex justify-between">
                {""}<img src={require("./icons/documenticon.svg").default} alt="adfsfs" />{" "}
                <button className="bg-[#009EF7] hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
                  Download
                </button>
              </div>
              <div className="flex justify-between flex-col">
                <h2 className="text-[20px] font-bold">Demo.pdf</h2>
                <p className="text-[16px]">03:03pm 12 Oct, 2002</p>
              </div>
            </div>
          </div>
          <div className="bg-white px-10 py-8 h-auto w-auto rounded-2xl flex flex-col lg:gap-1">
            <div className="text-[#2C294B] flex flex-col gap-3">
              <div className="flex justify-between">
                {""}<img src={require("./icons/documenticon.svg").default} alt="adfsfs" />{" "}
                <button className="bg-[#009EF7] hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
                  Download
                </button>
              </div>
              <div className="flex justify-between flex-col">
                <h2 className="text-[20px] font-bold">Demo.pdf</h2>
                <p className="text-[16px]">03:03pm 12 Oct, 2002</p>
              </div>
            </div>
          </div>
          <div className="bg-white px-10 py-8 h-auto w-auto rounded-2xl flex flex-col lg:gap-1">
            <div className="text-[#2C294B] flex flex-col gap-3">
              <div className="flex justify-between">
                {""}<img src={require("./icons/documenticon.svg").default} alt="adfsfs" />{" "}
                <button className="bg-[#009EF7] hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
                  Download
                </button>
              </div>
              <div className="flex justify-between flex-col">
                <h2 className="text-[20px] font-bold">Demo.pdf</h2>
                <p className="text-[16px]">03:03pm 12 Oct, 2002</p>
              </div>
            </div>
          </div>
          <div className="bg-white px-10 py-8 h-auto w-auto rounded-2xl flex flex-col lg:gap-1">
            <div className="text-[#2C294B] flex flex-col gap-3">
              <div className="flex justify-between">
                {""}<img src={require("./icons/documenticon.svg").default} alt="adfsfs" />{" "}
                <button className="bg-[#009EF7] hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
                  Download
                </button>
              </div>
              <div className="flex justify-between flex-col">
                <h2 className="text-[20px] font-bold">Demo.pdf</h2>
                <p className="text-[16px]">03:03pm 12 Oct, 2002</p>
              </div>
            </div>
          </div>
          <div className="bg-white px-10 py-8 h-auto w-auto rounded-2xl flex flex-col lg:gap-1">
            <div className="text-[#2C294B] flex flex-col gap-3">
              <div className="flex justify-between">
                {""}<img src={require("./icons/documenticon.svg").default} alt="adfsfs" />{" "}
                <button className="bg-[#009EF7] hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
                  Download
                </button>
              </div>
              <div className="flex justify-between flex-col">
                <h2 className="text-[20px] font-bold">Demo.pdf</h2>
                <p className="text-[16px]">03:03pm 12 Oct, 2002</p>
              </div>
            </div>
          </div>
          <div className="bg-white px-10 py-8 h-auto w-auto rounded-2xl flex flex-col lg:gap-1">
            <div className="text-[#2C294B] flex flex-col gap-3">
              <div className="flex justify-between">
                {""}<img src={require("./icons/documenticon.svg").default} alt="adfsfs" />{" "}
                <button className="bg-[#009EF7] hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
                  Download
                </button>
              </div>
              <div className="flex justify-between flex-col">
                <h2 className="text-[20px] font-bold">Demo.pdf</h2>
                <p className="text-[16px]">03:03pm 12 Oct, 2002</p>
              </div>
            </div>
          </div>
          <div className="bg-white px-10 py-8 h-auto w-auto rounded-2xl flex flex-col lg:gap-1">
            <div className="text-[#2C294B] flex flex-col gap-3">
              <div className="flex justify-between">
                {""}<img src={require("./icons/documenticon.svg").default} alt="adfsfs" />{" "}
                <button className="bg-[#009EF7] hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
                  Download
                </button>
              </div>
              <div className="flex justify-between flex-col">
                <h2 className="text-[20px] font-bold">Demo.pdf</h2>
                <p className="text-[16px]">03:03pm 12 Oct, 2002</p>
              </div>
            </div>
          </div>
          <div className="bg-white px-10 py-8 h-auto w-auto rounded-2xl flex flex-col lg:gap-1">
            <div className="text-[#2C294B] flex flex-col gap-3">
              <div className="flex justify-between">
                {""}<img src={require("./icons/documenticon.svg").default} alt="adfsfs" />{" "}
                <button className="bg-[#009EF7] hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
                  Download
                </button>
              </div>
              <div className="flex justify-between flex-col">
                <h2 className="text-[20px] font-bold">Demo.pdf</h2>
                <p className="text-[16px]">03:03pm 12 Oct, 2002</p>
              </div>
            </div>
          </div>
          <div className="bg-white px-10 py-8 h-auto w-auto rounded-2xl flex flex-col lg:gap-1">
            <div className="text-[#2C294B] flex flex-col gap-3">
              <div className="flex justify-between">
                {""}<img src={require("./icons/documenticon.svg").default} alt="adfsfs" />{" "}
                <button className="bg-[#009EF7] hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
                  Download
                </button>
              </div>
              <div className="flex justify-between flex-col">
                <h2 className="text-[20px] font-bold">Demo.pdf</h2>
                <p className="text-[16px]">03:03pm 12 Oct, 2002</p>
              </div>
            </div>
          </div>
          <div className="bg-white px-10 py-8 h-auto w-auto rounded-2xl flex flex-col lg:gap-1">
            <div className="text-[#2C294B] flex flex-col gap-3">
              <div className="flex justify-between">
                {""}<img src={require("./icons/documenticon.svg").default} alt="adfsfs" />{" "}
                <button className="bg-[#009EF7] hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
                  Download
                </button>
              </div>
              <div className="flex justify-between flex-col">
                <h2 className="text-[20px] font-bold">Demo.pdf</h2>
                <p className="text-[16px]">03:03pm 12 Oct, 2002</p>
              </div>
            </div>
          </div>
          <div className="bg-white px-10 py-8 h-auto w-auto rounded-2xl flex flex-col lg:gap-1">
            <div className="text-[#2C294B] flex flex-col gap-3">
              <div className="flex justify-between">
                {""}<img src={require("./icons/documenticon.svg").default} alt="adfsfs" />{" "}
                <button className="bg-[#009EF7] hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
                  Download
                </button>
              </div>
              <div className="flex justify-between flex-col">
                <h2 className="text-[20px] font-bold">Demo.pdf</h2>
                <p className="text-[16px]">03:03pm 12 Oct, 2002</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Documents;
