import React from "react";
import Navbar from "./components/Navbar";

const Billing = () => {
  return (
    <div className="absolute h-full w-full right-0 p-4 lg:p-4 overflow-x-auto">
      <Navbar />

      <div className="mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3">
        <div className="text-pcolor p-5 bg-white rounded-2xl">
          <p className="text-[#ADB0BE] text-[20px]">Plan type</p>
          <p className="text-[30px] font-bold">Unlimited</p>
        </div>
        <div className="text-pcolor p-5 bg-white rounded-2xl">
          <p className="text-[#ADB0BE] text-[20px]">Your Plan</p>
          <p className="text-[30px] font-bold">$100/monthly</p>
        </div>
        <div className="text-pcolor p-5 bg-white rounded-2xl">
          <p className="text-[#ADB0BE] text-[20px]">Start date</p>
          <p className="text-[30px] font-bold">22 May,2022</p>
        </div>
        <div className="text-pcolor p-5 bg-white rounded-2xl">
          <p className="text-[#ADB0BE] text-[20px]">Next Bill</p>
          <p className="text-[30px] font-bold">22 June,2022</p>
        </div>
      </div>

      <div className="bg-[#FF7D7D] px-5 py-7 mt-6 text-white rounded-2xl flex md:flex-row flex-col justify-between items-center">
        <div>
          <h1 className="text-[24px] font-bold">Cancel Subscription Plan</h1>
          <p className="text-sm">
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
            nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
            erat, sed diam voluptua. At vero eos et accu
          </p>
        </div>
        {/* btn */}
        <div className="flex justify-center items-center text-[#FF7D7D] mt-5">
          <button className="p-2 rounded-full bg-white w-36 hover:bg-mainbg mx-3">Change Plan</button>
          <button className="p-2 rounded-full bg-white w-36 hover:bg-mainbg">Cancle Plan</button>
        </div>
      </div>
    </div>
  );
};

export default Billing;
