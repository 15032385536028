import React from 'react'
import Navbar from "./components/Navbar";

const KeyLogger = () => {
  return (
    <div className=" absolute h-full w-full right-0 p-4 lg:p-4 overflow-x-auto">
      <Navbar />
      <div className="bg-white mt-8 overflow-x-auto  p-5 lg:p-8 w-full rounded-2xl">
        <table className="w-full text-sm text-left text-[#2C294B] text-[16px]">
          <thead className="text-xs text-gray-700 bg-transparent text-[16px]">
            <tr className="text-lbg">
              <th scope="col" className="py-1 lg:py-3 px-4  lg:px-6 text-base">
              Application
              </th>
              <th scope="col" className="py-1 lg:py-3 px-4  lg:px-6 text-base">
              Content
              </th>
              <th scope="col" className="py-1 lg:py-3 px-4  lg:px-6 text-base">
              Created  on
              </th>
            </tr>
          </thead>
          <tbody className='text-base'>
            <tr className="bg-[#F8F8F8]">
              <td className="py-6 px-7">
              Wireless settings (password)
              </td>
              <td className="py-6 px-7">pass-wifi-1234</td>
              <td className="py-6 px-7">18 Nov 2017 14:58</td>
            </tr>
            <tr className="bg-[#FFF]">
              <td className="py-6 px-7">
              Notes
              </td>
              <td className="py-6 px-7">pass-wifi-1234</td>
              <td className="py-6 px-7">18 Nov 2017 14:58</td>
            </tr>
            <tr className="bg-[#F8F8F8]">
              <td className="py-6 px-7">
              Gmail
              </td>
              <td className="py-6 px-7">pass-wifi-1234</td>
              <td className="py-6 px-7">18 Nov 2017 14:58</td>
            </tr>
            <tr className="bg-[#FFF]">
              <td className="py-6 px-7">
              Notes
              </td>
              <td className="py-6 px-7">pass-wifi-1234</td>
              <td className="py-6 px-7">18 Nov 2017 14:58</td>
            </tr>
            <tr className="bg-[#F8F8F8]">
              <td className="py-6 px-7">
              Notes
              </td>
              <td className="py-6 px-7">pass-wifi-1234</td>
              <td className="py-6 px-7">18 Nov 2017 14:58</td>
            </tr>
            <tr className="bg-[#FFF]">
              <td className="py-6 px-7">
              Notes
              </td>
              <td className="py-6 px-7">pass-wifi-1234</td>
              <td className="py-6 px-7">18 Nov 2017 14:58</td>
            </tr>
            <tr className="bg-[#F8F8F8]">
              <td className="py-6 px-7">
              Notes
              </td>
              <td className="py-6 px-7">pass-wifi-1234</td>
              <td className="py-6 px-7">18 Nov 2017 14:58</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default KeyLogger
