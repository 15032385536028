import React from "react";
import Navbar from "./components/Navbar";

const AudioFile = () => {
  return (
    <div className=" absolute h-full w-full right-0 p-4 lg:p-4 overflow-x-auto">
      <Navbar />

      <div className="mt-8 grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
        <div className="bg-white p-3 w-auto h-auto rounded-2xl overflow-hidden">
          <p className="text-pcolor text-[20px]">Audio File name</p>
          <p className="text-pcolor text-xl">03:03pm</p>

          <div className="flex justify-start items-center w-auto mt-1">
            <img src={require("./icons/audio/audio-play.svg").default} className='w-auto h-auto mr-4' alt="" />
            <img
              src={require("./icons/audio/audio-track-play.svg").default}
              className='w-[250px] h-auto'
              alt=""
            />
          </div>
        </div>
        <div className="bg-white p-3 w-auto h-auto rounded-2xl overflow-hidden">
          <p className="text-pcolor text-[20px]">Audio File name</p>
          <p className="text-pcolor text-xl">03:03pm</p>

          <div className="flex justify-start items-center w-auto mt-2">
            <img src={require("./icons/audio/adi.svg").default} className='w-auto h-auto mr-4' alt="" />
            <img
              src={require("./icons/audio/audio-track.svg").default}
              className='w-[250px] h-auto'
              alt=""
            />
          </div>
        </div>
        <div className="bg-white p-3 w-auto h-auto rounded-2xl overflow-hidden">
          <p className="text-pcolor text-[20px]">Audio File name</p>
          <p className="text-pcolor text-xl">03:03pm</p>

          <div className="flex justify-start items-center w-auto mt-2">
            <img src={require("./icons/audio/adi.svg").default} className='w-auto h-auto mr-4' alt="" />
            <img
              src={require("./icons/audio/audio-track.svg").default}
              className='w-[250px] h-auto'
              alt=""
            />
          </div>
        </div>
        <div className="bg-white p-3 w-auto h-auto rounded-2xl overflow-hidden">
          <p className="text-pcolor text-[20px]">Audio File name</p>
          <p className="text-pcolor text-xl">03:03pm</p>

          <div className="flex justify-start items-center w-auto mt-2">
            <img src={require("./icons/audio/adi.svg").default} className='w-auto h-auto mr-4' alt="" />
            <img
              src={require("./icons/audio/audio-track.svg").default}
              className='w-[250px] h-auto'
              alt=""
            />
          </div>
        </div>
        <div className="bg-white p-3 w-auto h-auto rounded-2xl overflow-hidden">
          <p className="text-pcolor text-[20px]">Audio File name</p>
          <p className="text-pcolor text-xl">03:03pm</p>

          <div className="flex justify-start items-center w-auto mt-2">
            <img src={require("./icons/audio/adi.svg").default} className='w-auto h-auto mr-4' alt="" />
            <img
              src={require("./icons/audio/audio-track.svg").default}
              className='w-[250px] h-auto'
              alt=""
            />
          </div>
        </div>
        <div className="bg-white p-3 w-auto h-auto rounded-2xl overflow-hidden">
          <p className="text-pcolor text-[20px]">Audio File name</p>
          <p className="text-pcolor text-xl">03:03pm</p>

          <div className="flex justify-start items-center w-auto mt-2">
            <img src={require("./icons/audio/adi.svg").default} className='w-auto h-auto mr-4' alt="" />
            <img
              src={require("./icons/audio/audio-track.svg").default}
              className='w-[250px] h-auto'
              alt=""
            />
          </div>
        </div>
        <div className="bg-white p-3 w-auto h-auto rounded-2xl overflow-hidden">
          <p className="text-pcolor text-[20px]">Audio File name</p>
          <p className="text-pcolor text-xl">03:03pm</p>

          <div className="flex justify-start items-center w-auto mt-2">
            <img src={require("./icons/audio/adi.svg").default} className='w-auto h-auto mr-4' alt="" />
            <img
              src={require("./icons/audio/audio-track.svg").default}
              className='w-[250px] h-auto'
              alt=""
            />
          </div>
        </div>
        <div className="bg-white p-3 w-auto h-auto rounded-2xl overflow-hidden">
          <p className="text-pcolor text-[20px]">Audio File name</p>
          <p className="text-pcolor text-xl">03:03pm</p>

          <div className="flex justify-start items-center w-auto mt-2">
            <img src={require("./icons/audio/adi.svg").default} className='w-auto h-auto mr-4' alt="" />
            <img
              src={require("./icons/audio/audio-track.svg").default}
              className='w-[250px] h-auto'
              alt=""
            />
          </div>
        </div>
        <div className="bg-white p-3 w-auto h-auto rounded-2xl overflow-hidden">
          <p className="text-pcolor text-[20px]">Audio File name</p>
          <p className="text-pcolor text-xl">03:03pm</p>

          <div className="flex justify-start items-center w-auto mt-2">
            <img src={require("./icons/audio/adi.svg").default} className='w-auto h-auto mr-4' alt="" />
            <img
              src={require("./icons/audio/audio-track.svg").default}
              className='w-[250px] h-auto'
              alt=""
            />
          </div>
        </div>
        
      </div>
    </div>
  );
};

export default AudioFile;
