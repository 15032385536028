import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import SideBar from "./components/SideBar";
import MTNavigation from "./components/MTNavigation";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter className="bg-mainbg">
    <React.StrictMode>
      <div className="flex justify-center align-middle lg:flex-row flex-col h-screen overflow-hidden">
        <MTNavigation />
        <SideBar />
        <App />
      </div>
    </React.StrictMode>
  </BrowserRouter>
);

reportWebVitals();
