import React from "react";
import Navbar from "./components/Navbar";

const WifiNetwork = () => {
  return (
    <div className=" absolute h-full w-full right-0 p-4 lg:p-4 overflow-x-auto">
      <Navbar />
      <div className="bg-white mt-8 overflow-x-auto  p-5 lg:p-8 w-full rounded-2xl">
        <table className="w-full text-sm text-left text-[#2C294B] text-[16px]">
          <thead className="text-xs text-gray-700 bg-transparent text-[16px]">
            <tr className="text-lbg">
              <th scope="col" className="py-1 lg:py-3 px-4  lg:px-6 text-base">
              Network name (SSID)
              </th>
              <th scope="col" className="py-1 lg:py-3 px-4  lg:px-6 text-base">
              Authentication
              </th>
              <th scope="col" className="py-1 lg:py-3 px-4  lg:px-6 text-base">
              Cipher
              </th>
              <th scope="col" className="py-1 lg:py-3 px-4  lg:px-6 text-base">
              Key 
              </th>
              <th scope="col" className="py-1 lg:py-3 px-4  lg:px-6 text-base">
              Protocol 
              </th>
              <th scope="col" className="py-1 lg:py-3 px-4  lg:px-6 text-base">
              Hidden
              </th>
              <th scope="col" className="py-1 lg:py-3 px-4  lg:px-6 text-base">
              Detected on
              </th>
            </tr>
          </thead>
          <tbody className="text-base">
            <tr className="bg-[#F8F8F8]">
              <td className="py-6 px-7">
              Hotel Black Panther Wifi{" "}
              </td>
              <td className="py-6 px-7">WPA/WPA2</td>
              <td className="py-6 px-7">WEP40, WEP104, TKIP, CCMP</td>
              <td className="py-6 px-7">None, WPA PSK</td>
              <td className="py-6 px-7">RSN</td>
              <td className="py-6 px-7">No</td>
              <td className="py-6 px-7">03 Oct 2019 14:28</td>
            </tr>
            <tr className="bg-[#FFF]">
              <td className="py-6 px-7">
              Melany Home Wifi{" "}
              </td>
              <td className="py-6 px-7">Melany Home Wifi</td>
              <td className="py-6 px-7">TKIP, CCMP</td>
              <td className="py-6 px-7">None, WPA PSK</td>
              <td className="py-6 px-7">RSN</td>
              <td className="py-6 px-7">Yes</td>
              <td className="py-6 px-7">03 Oct 2019 14:28</td>
            </tr>
            <tr className="bg-[#F8F8F8]">
              <td className="py-6 px-7">
              Hotel Black Panther Wifi{" "}
              </td>
              <td className="py-6 px-7">WPA/WPA2</td>
              <td className="py-6 px-7">WEP40, WEP104, TKIP, CCMP</td>
              <td className="py-6 px-7">None, WPA PSK</td>
              <td className="py-6 px-7">RSN</td>
              <td className="py-6 px-7">No</td>
              <td className="py-6 px-7">03 Oct 2019 14:28</td>
            </tr>
            <tr className="bg-[#FFF]">
              <td className="py-6 px-7">
              Melany Home Wifi{" "}
              </td>
              <td className="py-6 px-7">Melany Home Wifi</td>
              <td className="py-6 px-7">TKIP, CCMP</td>
              <td className="py-6 px-7">None, WPA PSK</td>
              <td className="py-6 px-7">RSN</td>
              <td className="py-6 px-7">Yes</td>
              <td className="py-6 px-7">03 Oct 2019 14:28</td>
            </tr>
            <tr className="bg-[#F8F8F8]">
              <td className="py-6 px-7">
              Hotel Black Panther Wifi{" "}
              </td>
              <td className="py-6 px-7">WPA/WPA2</td>
              <td className="py-6 px-7">WEP40, WEP104, TKIP, CCMP</td>
              <td className="py-6 px-7">None, WPA PSK</td>
              <td className="py-6 px-7">RSN</td>
              <td className="py-6 px-7">No</td>
              <td className="py-6 px-7">03 Oct 2019 14:28</td>
            </tr>
            <tr className="bg-[#FFF]">
              <td className="py-6 px-7">
              Melany Home Wifi{" "}
              </td>
              <td className="py-6 px-7">Melany Home Wifi</td>
              <td className="py-6 px-7">TKIP, CCMP</td>
              <td className="py-6 px-7">None, WPA PSK</td>
              <td className="py-6 px-7">RSN</td>
              <td className="py-6 px-7">Yes</td>
              <td className="py-6 px-7">03 Oct 2019 14:28</td>
            </tr>
            <tr className="bg-[#F8F8F8]">
              <td className="py-6 px-7">
              Hotel Black Panther Wifi{" "}
              </td>
              <td className="py-6 px-7">WPA/WPA2</td>
              <td className="py-6 px-7">WEP40, WEP104, TKIP, CCMP</td>
              <td className="py-6 px-7">None, WPA PSK</td>
              <td className="py-6 px-7">RSN</td>
              <td className="py-6 px-7">No</td>
              <td className="py-6 px-7">03 Oct 2019 14:28</td>
            </tr>
            <tr className="bg-[#FFF]">
              <td className="py-6 px-7">
              Melany Home Wifi{" "}
              </td>
              <td className="py-6 px-7">Melany Home Wifi</td>
              <td className="py-6 px-7">TKIP, CCMP</td>
              <td className="py-6 px-7">None, WPA PSK</td>
              <td className="py-6 px-7">RSN</td>
              <td className="py-6 px-7">Yes</td>
              <td className="py-6 px-7">03 Oct 2019 14:28</td>
            </tr>
            <tr className="bg-[#F8F8F8]">
              <td className="py-6 px-7">
              Hotel Black Panther Wifi{" "}
              </td>
              <td className="py-6 px-7">WPA/WPA2</td>
              <td className="py-6 px-7">WEP40, WEP104, TKIP, CCMP</td>
              <td className="py-6 px-7">None, WPA PSK</td>
              <td className="py-6 px-7">RSN</td>
              <td className="py-6 px-7">No</td>
              <td className="py-6 px-7">03 Oct 2019 14:28</td>
            </tr>
            <tr className="bg-[#FFF]">
              <td className="py-6 px-7">
              Melany Home Wifi{" "}
              </td>
              <td className="py-6 px-7">Melany Home Wifi</td>
              <td className="py-6 px-7">TKIP, CCMP</td>
              <td className="py-6 px-7">None, WPA PSK</td>
              <td className="py-6 px-7">RSN</td>
              <td className="py-6 px-7">Yes</td>
              <td className="py-6 px-7">03 Oct 2019 14:28</td>
            </tr>
            <tr className="bg-[#F8F8F8]">
              <td className="py-6 px-7">
              Hotel Black Panther Wifi{" "}
              </td>
              <td className="py-6 px-7">WPA/WPA2</td>
              <td className="py-6 px-7">WEP40, WEP104, TKIP, CCMP</td>
              <td className="py-6 px-7">None, WPA PSK</td>
              <td className="py-6 px-7">RSN</td>
              <td className="py-6 px-7">No</td>
              <td className="py-6 px-7">03 Oct 2019 14:28</td>
            </tr>
            <tr className="bg-[#FFF]">
              <td className="py-6 px-7">
              Melany Home Wifi{" "}
              </td>
              <td className="py-6 px-7">Melany Home Wifi</td>
              <td className="py-6 px-7">TKIP, CCMP</td>
              <td className="py-6 px-7">None, WPA PSK</td>
              <td className="py-6 px-7">RSN</td>
              <td className="py-6 px-7">Yes</td>
              <td className="py-6 px-7">03 Oct 2019 14:28</td>
            </tr>
            
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default WifiNetwork;
