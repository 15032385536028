import React from "react";
import Navbar from "./components/Navbar";

const Applications = () => {
  return (
    <div className=" absolute h-full w-full right-0 p-4 lg:p-4 overflow-x-auto mb-10">
      <Navbar />
      <div className="overflow-x-auto w-full">
        <div className="h-auto mt-8 grid grid-cols-1 lg:grid-cols-2 gap-3 lg:gap-4 ">
          {/* ================ */}
          <div className="bg-white px-7 py-5 h-auto w-auto rounded-2xl flex flex-col lg:gap-[2rem]">
            <div className="flex justify-between flex-col sm:flex-row">
              <div className="text-[#2C294B] flex items-center gap-[1rem]">
                {""}
                <img
                  src={require("./icons/facebook.svg").default}
                  alt="adfsfs"
                  className="h-[36px]"
                />{" "}
                <div className="flex flex-col">
                  <h2 className="text-[20px] font-bold">
                    facebook
                  </h2>
                  <p className="text-[16px]">com.facebook.katana</p>
                </div>
              </div>
              <div>
                <p className="text-[#ADB0BE]">02 Oct 2013 20:24</p>
                <p className="text-[#009EF7] text-[20px] font-semibold">Version:3.7</p>
              </div>
            </div>
            {/* ======sec-col========= */}
            <div className="text-[#2C294B] text-[20px] flex justify-between flex-col sm:flex-row">
              <p>Version code: <span className="font-bold"> 381878</span></p>
              <button className="bg-[#009EF7] hover:bg-blue-600 mt-3 sm:mt-0 text-white py-2 px-4 rounded">
                    Open the website
                    </button>
            </div>
          </div>

          <div className="bg-white px-7 py-5 h-auto w-auto rounded-2xl flex flex-col lg:gap-[2rem]">
            <div className="flex justify-between flex-col sm:flex-row">
              <div className="text-[#2C294B] flex items-center gap-[1rem]">
                {""}
                <img
                  src={require("./icons/gmail.svg").default}
                  alt="adfsfs"
                  className="h-[36px]"
                />{" "}
                <div className="flex flex-col">
                  <h2 className="text-[20px] font-bold">
                  Gmail
                  </h2>
                  <p className="text-[16px]">com.facebook.katana</p>
                </div>
              </div>
              <div>
                <p className="text-[#ADB0BE]">02 Oct 2013 20:24</p>
                <p className="text-[#009EF7] text-[20px] font-semibold">Version:3.7</p>
              </div>
            </div>
            {/* ======sec-col========= */}
            <div className="text-[#2C294B] text-[20px] flex justify-between flex-col sm:flex-row">
              <p>Version code: <span className="font-bold"> 381878</span></p>
              <button className="bg-[#009EF7] hover:bg-blue-600 mt-3 sm:mt-0 text-white py-2 px-4 rounded">
                    Open the website
                    </button>
            </div>
          </div>

          <div className="bg-white px-7 py-5 h-auto w-auto rounded-2xl flex flex-col lg:gap-[2rem]">
            <div className="flex justify-between flex-col sm:flex-row">
              <div className="text-[#2C294B] flex items-center gap-[1rem]">
                {""}
                <img
                  src={require("./icons/gp.svg").default}
                  alt="adfsfs"
                  className="h-[36px]"
                />{" "}
                <div className="flex flex-col">
                  <h2 className="text-[20px] font-bold">
                  Google Play Music
                  </h2>
                  <p className="text-[16px]">com.facebook.katana</p>
                </div>
              </div>
              <div>
                <p className="text-[#ADB0BE]">02 Oct 2013 20:24</p>
                <p className="text-[#009EF7] text-[20px] font-semibold">Version:3.7</p>
              </div>
            </div>
            {/* ======sec-col========= */}
            <div className="text-[#2C294B] text-[20px] flex justify-between flex-col sm:flex-row">
              <p>Version code: <span className="font-bold"> 381878</span></p>
              <button className="bg-[#009EF7] hover:bg-blue-600 mt-3 sm:mt-0 text-white py-2 px-4 rounded">
                    Open the website
                    </button>
            </div>
          </div>

          <div className="bg-white px-7 py-5 h-auto w-auto rounded-2xl flex flex-col lg:gap-[2rem]">
            <div className="flex justify-between flex-col sm:flex-row">
              <div className="text-[#2C294B] flex items-center gap-[1rem]">
                {""}
                <img
                  src={require("./icons/whatsapp.svg").default}
                  alt="adfsfs"
                  className="h-[36px]"
                />{" "}
                <div className="flex flex-col">
                  <h2 className="text-[20px] font-bold">
                    Whatshapp
                  </h2>
                  <p className="text-[16px]">com.facebook.katana</p>
                </div>
              </div>
              <div>
                <p className="text-[#ADB0BE]">02 Oct 2013 20:24</p>
                <p className="text-[#009EF7] text-[20px] font-semibold">Version:3.7</p>
              </div>
            </div>
            {/* ======sec-col========= */}
            <div className="text-[#2C294B] text-[20px] flex justify-between flex-col sm:flex-row">
              <p>Version code: <span className="font-bold"> 381878</span></p>
              <button className="bg-[#009EF7] hover:bg-blue-600 mt-3 sm:mt-0 text-white py-2 px-4 rounded">
                    Open the website
                    </button>
            </div>
          </div>

          <div className="bg-white px-7 py-5 h-auto w-auto rounded-2xl flex flex-col lg:gap-[2rem]">
            <div className="flex justify-between flex-col sm:flex-row">
              <div className="text-[#2C294B] flex items-center gap-[1rem]">
                {""}
                <img
                  src={require("./icons/instagram.svg").default}
                  alt="adfsfs"
                  className="h-[36px]"
                />{" "}
                <div className="flex flex-col">
                  <h2 className="text-[20px] font-bold">
                    Instagram
                  </h2>
                  <p className="text-[16px]">com.facebook.katana</p>
                </div>
              </div>
              <div>
                <p className="text-[#ADB0BE]">02 Oct 2013 20:24</p>
                <p className="text-[#009EF7] text-[20px] font-semibold">Version:3.7</p>
              </div>
            </div>
            {/* ======sec-col========= */}
            <div className="text-[#2C294B] text-[20px] flex justify-between flex-col sm:flex-row">
              <p>Version code: <span className="font-bold"> 381878</span></p>
              <button className="bg-[#009EF7] hover:bg-blue-600 mt-3 sm:mt-0 text-white py-2 px-4 rounded">
                    Open the website
                    </button>
            </div>
          </div>

          <div className="bg-white px-7 py-5 h-auto w-auto rounded-2xl flex flex-col lg:gap-[2rem]">
            <div className="flex justify-between flex-col sm:flex-row">
              <div className="text-[#2C294B] flex items-center gap-[1rem]">
                {""}
                <img
                  src={require("./icons/snapchat.svg").default}
                  alt="adfsfs"
                  className="h-[36px]"
                />{" "}
                <div className="flex flex-col">
                  <h2 className="text-[20px] font-bold">
                    Snapchat
                  </h2>
                  <p className="text-[16px]">com.facebook.katana</p>
                </div>
              </div>
              <div>
                <p className="text-[#ADB0BE]">02 Oct 2013 20:24</p>
                <p className="text-[#009EF7] text-[20px] font-semibold">Version:3.7</p>
              </div>
            </div>
            {/* ======sec-col========= */}
            <div className="text-[#2C294B] text-[20px] flex justify-between flex-col sm:flex-row">
              <p>Version code: <span className="font-bold"> 381878</span></p>
              <button className="bg-[#009EF7] hover:bg-blue-600 mt-3 sm:mt-0 text-white py-2 px-4 rounded">
                    Open the website
                    </button>
            </div>
          </div>

          <div className="bg-white px-7 py-5 h-auto w-auto rounded-2xl flex flex-col lg:gap-[2rem]">
            <div className="flex justify-between flex-col sm:flex-row">
              <div className="text-[#2C294B] flex items-center gap-[1rem]">
                {""}
                <img
                  src={require("./icons/dropbox.svg").default}
                  alt="adfsfs"
                  className="h-[36px]"
                />{" "}
                <div className="flex flex-col">
                  <h2 className="text-[20px] font-bold">
                    Dropbox
                  </h2>
                  <p className="text-[16px]">com.facebook.katana</p>
                </div>
              </div>
              <div>
                <p className="text-[#ADB0BE]">02 Oct 2013 20:24</p>
                <p className="text-[#009EF7] text-[20px] font-semibold">Version:3.7</p>
              </div>
            </div>
            {/* ======sec-col========= */}
            <div className="text-[#2C294B] text-[20px] flex justify-between flex-col sm:flex-row">
              <p>Version code: <span className="font-bold"> 381878</span></p>
              <button className="bg-[#009EF7] hover:bg-blue-600 mt-3 sm:mt-0 text-white py-2 px-4 rounded">
                    Open the website
                    </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Applications;
