import React from "react";
import { NavLink } from "react-router-dom";


import "../App.css";

const SideBar = () => (
  <div className="w-[320px] h-full pt-10 bg-[#2c294b] text-[#D7D6DC] sidebar">
    <div className="bg-white w-full h-full rounded-tr-[20px] p-8 pb-20">
      <div className="text-[#08a1f7] font-bold text-2xl mb-8">
      <img src={ require('../images/logo.png') } alt="" />
      </div>
      <div className="overflow-auto h-full sidebar">
        <ul>
          <li className="my-3 hover:text-lbg">
            <NavLink to="/" className="text-lg flex items-center justify-start" activeclassname='active'>
              {/* <RxDashboard className="inline-block w-7 h-7 mr-2" /> */}
              <img src={ require('../icons/DashBoard/sideBarIcons/Dashboard.svg').default } className="inline-block mr-2 text-[#2c294b] " alt="" />
              {/* <img src={Dashboard} alt="dashboard" className="inline-block mr-2 text-[#2c294b]" /> */}
              <span className="t-color">Dashboard</span>
              </NavLink>
          </li>
          <li className="my-3 hover:text-lbg transition">
            <NavLink to="/deviceinfo" className="text-lg" activeclassname='active'>
            <img src={ require('../icons/DashBoard/sideBarIcons/DeviceInfo.svg').default } className="inline-block mr-2 text-[#2c294b]" alt="" />
            {/* <img src={DeviceInfo} alt="dashboard" className="inline-block mr-2 text-[#2c294b]" /> */}
              <span>Device Info</span>
             
            </NavLink>
          </li>
          <li className="my-3 hover:text-lbg transition">
            <NavLink to="/callhistory" className="text-lg" activeclassname='active'>
            <img src={ require('../icons/DashBoard/sideBarIcons/CallHistory.svg').default } className="inline-block mr-2 text-[#2c294b]" alt="" />
            {/* <img src={CallHistory} alt="dashboard" className="inline-block mr-2 text-[#2c294b]" /> */}
              Call History
            </NavLink>
          </li>
          <li className="my-3 hover:text-lbg transition">
            <NavLink to="/smshistory" className="text-lg" activeclassname='active'>
            <img src={ require('../icons/DashBoard/sideBarIcons/SMSHistory.svg').default } className="inline-block mr-2 text-[#2c294b]" alt="" />
            {/* <img src={SMSHistory} alt="dashboard" className="inline-block mr-2 text-[#2c294b]" /> */}
              SMS History
            </NavLink>
          </li>
          <li className="my-3 hover:text-lbg transition">
            <NavLink to="/addressBook" className="text-lg" activeclassname='active'>
            <img src={ require('../icons/DashBoard/sideBarIcons/AddressBook.svg').default } className="inline-block mr-2 text-[#2c294b]" alt="" />
            {/* <img src={AddressBook} alt="dashboard" className="inline-block mr-2 text-[#2c294b]" /> */}
              Address Book
            </NavLink>
          </li>
          <li className="my-3 hover:text-lbg transition">
            <NavLink to="/gpslocation" className="text-lg" activeclassname='active'>
            <img src={ require('../icons/DashBoard/sideBarIcons/GPSLocation.svg').default } className="inline-block mr-2 text-[#2c294b]" alt="" />
              GPS Location
            </NavLink>
          </li>
          <li className="my-3 hover:text-lbg transition">
            <NavLink to="/iplocation" className="text-lg" activeclassname='active'>
            <img src={ require('../icons/DashBoard/sideBarIcons/IPLocations.svg').default } className="inline-block mr-2 text-[#2c294b]" alt="" />
              IP Location
            </NavLink>
          </li>
          <li className="my-3 hover:text-lbg transition">
            <NavLink to="/images" className="text-lg" activeclassname='active'>
            <img src={ require('../icons/DashBoard/sideBarIcons/Images.svg').default } className="inline-block mr-2 text-[#2c294b]" alt="" />
              Image
            </NavLink>
          </li>
          <li className="my-3 hover:text-lbg transition">
            <NavLink to="/videofile" className="text-lg" activeclassname='active'>
            <img src={ require('../icons/DashBoard/sideBarIcons/VideoFiles.svg').default } className="inline-block mr-2 text-[#2c294b]" alt="" />
              Video File
            </NavLink>
          </li>
          <li className="my-3 hover:text-lbg transition">
            <NavLink to="/audiofile" className="text-lg" activeclassname='active'>
            <img src={ require('../icons/DashBoard/sideBarIcons/AudioFiles.svg').default } className="inline-block mr-2 text-[#2c294b]" alt="" />
              
              Audio File
            </NavLink>
          </li>
          <li className="my-3 hover:text-lbg transition">
            <NavLink to="/documents" className="text-lg" activeclassname='active'>
            <img src={ require('../icons/DashBoard/sideBarIcons/Documents.svg').default } className="inline-block mr-2 text-[#2c294b]" alt="" />
              Documents
            </NavLink>
          </li>
          <li className="my-3 hover:text-lbg transition">
            <NavLink to="/browserhistory" className="text-lg" activeclassname='active'>
            <img src={ require('../icons/DashBoard/sideBarIcons/BrowserHistory.svg').default } className="inline-block mr-2 text-[#2c294b]" alt="" />
              Browser History
            </NavLink>
          </li>
          <li className="my-3 hover:text-lbg transition">
            <NavLink to="/browserbookmarkhistory" className="text-lg" activeclassname='active'>
            <img src={ require('../icons/DashBoard/sideBarIcons/BrowserBookmarks.svg').default } className="inline-block mr-2 text-[#2c294b]" alt="" />
              Browser Bookmark
            </NavLink>
          </li>
          <li className="my-3 hover:text-lbg transition">
            <NavLink to="/tellegrammessages" className="text-lg" activeclassname='active'>
            <img src={ require('../icons/DashBoard/sideBarIcons/SocialApps.svg').default } className="inline-block mr-2 text-[#2c294b]" alt="" />
              Social Apps
            </NavLink>
          </li>
          <li className="my-3 hover:text-lbg transition">
            <NavLink to="/applications" className="text-lg" activeclassname='active'>
            <img src={ require('../icons/DashBoard/sideBarIcons/Applications.svg').default } className="inline-block mr-2 text-[#2c294b]" alt="" />
              Application
            </NavLink>
          </li>
          <li className="my-3 hover:text-lbg transition">
            <NavLink to="/calendar" className="text-lg" activeclassname='active'>
            <img src={ require('../icons/DashBoard/sideBarIcons/EventCalendar.svg').default } className="inline-block mr-2 text-[#2c294b]" alt="" />
              EventCalender
            </NavLink>
          </li>
          <li className="my-3 hover:text-lbg transition">
            <NavLink to="/wifinetwork" className="text-lg" activeclassname='active'>
            <img src={ require('../icons/DashBoard/sideBarIcons/WifiNetwork.svg').default } className="inline-block mr-2 text-[#2c294b]" alt="" />
              Wifi Network
            </NavLink>
          </li>
          <li className="my-3 hover:text-lbg transition">
            <NavLink to="/keylogger" className="text-lg" activeclassname='active'>
            <img src={ require('../icons/DashBoard/sideBarIcons/KeyLogger.svg').default } className="inline-block mr-2 text-[#2c294b]" alt="" />
              Key Logger
            </NavLink>
          </li>
          <li className="my-3 hover:text-lbg transition">
            <NavLink to="/clipboardlogs" className="text-lg" activeclassname='active'>
            <img src={ require('../icons/DashBoard/sideBarIcons/ClipboardLogs.svg').default } className="inline-block mr-2 text-[#2c294b]" alt="" />
              Clipboard Logs
            </NavLink>
          </li>
          <li className="my-3 hover:text-lbg transition">
            <NavLink to="/simchanges" className="text-lg" activeclassname='active'>
            <img src={ require('../icons/DashBoard/sideBarIcons/SimChanges.svg').default } className="inline-block mr-2 text-[#2c294b]" alt="" />
              Sim Changes
            </NavLink>
          </li>
          <li className="my-3 hover:text-lbg transition">
            <NavLink to="/account" className="text-lg" activeclassname='active'>
            <img src={ require('../icons/DashBoard/sideBarIcons/Account.svg').default } className="inline-block mr-2 text-[#2c294b]" alt="" />
              Account
            </NavLink>
          </li>
          <li className="my-3 hover:text-lbg transition">
            <NavLink to="/emails" className="text-lg" activeclassname='active'>
            <img src={ require('../icons/DashBoard/sideBarIcons/EmailHistory.svg').default } className="inline-block mr-2 text-[#2c294b]" alt="" />
              Email History
            </NavLink>
          </li>
          <li className="my-3 hover:text-lbg transition">
            <NavLink to="/dataexport" className="text-lg" activeclassname='active'>
            <img src={ require('../icons/DashBoard/sideBarIcons/DataExport.svg').default } className="inline-block mr-2 text-[#2c294b]" alt="" />
              Data Export
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
   </div>
);

export default SideBar;
