import React from "react";
import Navbar from "./components/Navbar";
const DataExport = () => {
  return (
    <div className=" absolute h-full w-full right-0 p-4 lg:p-4 overflow-x-auto mb-10">
      <Navbar />
      <div className="overflow-x-auto w-full">
        <div className="h-auto mt-8 grid grid-cols-1 lg:grid-cols-2 gap-3 lg:gap-4 ">
          {/* ================ */}
          <div className="bg-white px-7 py-5 h-auto w-auto rounded-2xl flex flex-col lg:gap-[2rem]">
            <div className="flex justify-between flex-col sm:flex-row">
              <div className="text-[#2C294B] flex items-center gap-[1rem]">
                {""}
                <img
                  src={require("./icons/sms-history.svg").default}
                  alt="adfsfs"
                  className="h-[36px]"
                />{" "}
                <div className="flex flex-col">
                  <h2 className="text-[20px] font-bold">SMS History</h2>
                  <p className="text-[16px]">English (USA)</p>
                </div>
              </div>
              <button className="sm:block hidden text-[16px] text-xl bg-[#009EF7] hover:bg-blue-600 mt-3 sm:mt-0 text-white py-2 px-4 rounded">
                Download CSV File
              </button>
            </div>
            {/* ======sec-col========= */}
            <div className="text-[#2C294B] text-[20px] flex justify-between flex-col sm:flex-row">
              <div>
                <p>
                  Device:{" "}
                  <span className="font-bold">
                    {" "}
                    Sumsung s22 Ultra (Demo Device)
                  </span>
                </p>
                <p className="text-[16px]">Process at: 19 May 2017 18:55</p>
                <p className="text-[16px]">Submited at: 19 May 2017 10:48</p>
              </div>
              <button className="sm:hidden text-[16px] text-xl bg-[#009EF7] hover:bg-blue-600 mt-3 sm:mt-0 text-white py-2 px-4 rounded">
                Download CSV File
              </button>
            </div>
          </div>
          <div className="bg-white px-7 py-5 h-auto w-auto rounded-2xl flex flex-col lg:gap-[2rem]">
            <div className="flex justify-between flex-col sm:flex-row">
              <div className="text-[#2C294B] flex items-center gap-[1rem]">
                {""}
                <img
                  src={require("./icons/call-history.svg").default}
                  alt="adfsfs"
                  className="h-[36px]"
                />{" "}
                <div className="flex flex-col">
                  <h2 className="text-[20px] font-bold">Call History</h2>
                  <p className="text-[16px]">English (USA)</p>
                </div>
              </div>
              <button className="sm:block hidden text-[16px] text-xl bg-[#009EF7] hover:bg-blue-600 mt-3 sm:mt-0 text-white py-2 px-4 rounded">
                Download CSV File
              </button>
            </div>
            {/* ======sec-col========= */}
            <div className="text-[#2C294B] text-[20px] flex justify-between flex-col sm:flex-row">
              <div>
                <p>
                  Device:{" "}
                  <span className="font-bold">
                    {" "}
                    Sumsung s22 Ultra (Demo Device)
                  </span>
                </p>
                <p className="text-[16px]">Process at: 19 May 2017 18:55</p>
                <p className="text-[16px]">Submited at: 19 May 2017 10:48</p>
              </div>
              <button className="sm:hidden text-[16px] text-xl bg-[#009EF7] hover:bg-blue-600 mt-3 sm:mt-0 text-white py-2 px-4 rounded">
                Download CSV File
              </button>
            </div>
          </div>
          
        </div>
        
      </div>
    </div>
  );
};

export default DataExport;
