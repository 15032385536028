import React from "react";
import Navbar from "./components/Navbar";
import "./App.css";

// cards
import networkStatus from "./icons/DashBoard/card icons/networkStatus.svg";
import battery from "./icons/DashBoard/card icons/battery.svg";
import Expires from "./icons/DashBoard/card icons/expires.svg";
import gps from "./icons/DashBoard/card icons/GPS.svg";
import preminum from "./icons/DashBoard/card icons/Preminum.svg";
import Update from "./icons/DashBoard/card icons/Update.svg";

const DashBoard = () => {
  return (
    <div className=" absolute h-full w-full right-0 p-4 lg:p-4 overflow-x-auto">
      <Navbar />

      {/* cards */}

      <div className="h-auto mt-8 grid lg:grid-cols-3 md:grid-cols-2 gap-3 lg:gap-4">
        <div className="bg-white px-10 py-8 h-auto w-auto rounded-2xl flex items-center justify-between">
          <div>
            <p className=" text-[18px] text-[#6890A9]">Network status</p>
            <h1 className="text-[#2C294B] text-[26px] font-medium">Mobile, Vpn</h1>
          </div>
          <img src={networkStatus} alt="networkStatus" />
        </div>
        <div className="bg-white px-10 py-8 h-auto w-auto rounded-2xl flex items-center justify-between">
          <div>
            <p className=" text-[18px] text-[#6890A9]">Location</p>
            <h1 className="text-[#2C294B] text-[26px] font-medium">GPS + Network</h1>
          </div>
          <img src={gps} alt="networkStatus" />
        </div>
        <div className="bg-white px-10 py-8 h-auto w-auto rounded-2xl flex items-center justify-between">
          <div>
            <p className=" text-[18px] text-[#6890A9]">Device battery</p>
            <h1 className="text-[#2C294B] text-[26px] font-medium">49 %</h1>
          </div>
          <img src={battery} alt="networkStatus" />
        </div>
        <div className="bg-white px-10 py-8 h-auto w-auto rounded-2xl flex items-center justify-between">
          <div>
            <p className=" text-[18px] text-[#6890A9]">Last Updates</p>
            <h1 className="text-[#2C294B] text-[26px] font-medium">24 feb 2020</h1>
          </div>
          <img src={Update} alt="networkStatus" />
        </div>
        <div className="bg-white px-10 py-8 h-auto w-auto rounded-2xl flex items-center justify-between">
          <div>
            <p className=" text-[18px] text-[#6890A9]">Plan</p>
            <h1 className="text-[#2C294B] text-[26px] font-medium">Premium</h1>
          </div>
          <img src={preminum} alt="networkStatus" />
        </div>
        <div className="bg-white px-10 py-8 h-auto w-auto rounded-2xl flex items-center justify-between">
          <div>
            <p className=" text-[18px] text-[#6890A9]">Expire on</p>
            <h1 className="text-[#2C294B] text-[26px] font-medium">11 Feb 2030</h1>
          </div>
          <img src={Expires} alt="networkStatus" />
        </div>
      </div>

      {/* tabls */}

      <div className="grid gap-3 lg:gap-4 lg:grid-cols-2 my-8 mb-16">
        <div className="bg-white p-5 lg:p-8 w-full rounded-2xl">
          <table id="customers">
            <tr>
              <td className="flex justify-start items-center text-sm">
                {" "}
                <img src={ require('./icons/DashBoard/table 1/CallHistory.svg').default } className="inline-block mr-[10px]" alt="" />{" "}
                Call History
              </td>
              <td className="text-right">0/45</td>
            </tr>

            <tr>
              <td className="flex justify-start items-center text-sm">
                {" "}
                <img src={ require('./icons/DashBoard/table 1/smshistory.svg').default } className="inline-block mr-[10px]" alt="" />{" "}                  
               SMS
              </td>
              <td className="text-right">0/45</td>
            </tr>

            <tr>
              <td className="flex justify-start items-center text-sm">
                {" "}
                <img src={ require('./icons/DashBoard/table 1/smshistory.svg').default } className="inline-block mr-[10px]" alt="" />{" "}
                Address Book
              </td>
              <td className="text-right">0/45</td>
            </tr>

            <tr>
              <td className="flex justify-start items-center text-sm">
                {" "}
                <img src={ require('./icons/DashBoard/table 1/gpslocation.svg').default } className="inline-block mr-[10px]" alt="" />{" "}
                Gps Location
              </td>
              <td className="text-right">0/45</td>
            </tr>

            <tr>
              <td className="flex justify-start items-center text-sm">
                {" "}
                <img src={ require('./icons/DashBoard/table 1/iplocation.svg').default } className="inline-block mr-[10px]" alt="" />{" "}
                IP Locations
              </td>
              <td className="text-right">0/45</td>
            </tr>

            <tr>
              <td className="flex justify-start items-center text-sm">
                {" "}
                <img src={ require('./icons/DashBoard/table 1/images.svg').default } className="inline-block mr-[10px]" alt="" />{" "}
                Images
              </td>
              <td className="text-right">0/45</td>
            </tr>

            <tr>
              <td className="flex justify-start items-center text-sm">
                {" "}
                <img src={ require('./icons/DashBoard/table 1/videofiles.svg').default } className="inline-block mr-[10px]" alt="" />{" "}
                Video Files
              </td>
              <td className="text-right">0/45</td>
            </tr>

            <tr>
              <td className="flex justify-start items-center text-sm">
                {" "}
                <img src={ require('./icons/DashBoard/table 1/audiofiles.svg').default } className="inline-block mr-[10px]" alt="" />{" "}
                Audio Files
              </td>
              <td className="text-right">0/45</td>
            </tr>

            <tr>
              <td className="flex justify-start items-center text-sm">
                {" "}
                <img src={ require('./icons/DashBoard/table 1/documents.svg').default } className="inline-block mr-[10px]" alt="" />{" "}
               Documents
              </td>
              <td className="text-right">0/45</td>
            </tr>

            <tr>
              <td className="flex justify-start items-center text-sm">
                {" "}
                <img src={ require('./icons/DashBoard/table 1/eventcalender.svg').default } className="inline-block mr-[10px]" alt="" />{" "}
                Event Calander
              </td>
              <td className="text-right">0/45</td>
            </tr>

            <tr>
              <td className="flex justify-start items-center text-sm">
                {" "}
                <img src={ require('./icons/DashBoard/table 1/installedapps.svg').default } className="inline-block mr-[10px]" alt="" />{" "}
                Installed Apps
              </td>
              <td className="text-right">0/45</td>
            </tr>

            <tr>
              <td className="flex justify-start items-center text-sm">
                {" "}
                <img src={ require('./icons/DashBoard/table 1/keylogger.svg').default } className="inline-block mr-[10px]" alt="" />{" "}
                Key Logger
              </td>
              <td className="text-right">0/45</td>
            </tr>

            <tr>
              <td className="flex justify-start items-center text-sm">
                {" "}
                <img src={ require('./icons/DashBoard/table 1/simchanges.svg').default } className="inline-block mr-[10px]" alt="" />{" "}
                SIM Changes
              </td>
              <td className="text-right">0/45</td>
            </tr>
          </table>
        </div>

        <div className="bg-white p-5 lg:p-8 w-full rounded-2xl">
          <table id="customers">
            <tr>
              <td className="flex justify-start items-center text-sm">
                {" "}
                <img src={ require('./icons/DashBoard/table 1/telegrammessages.svg').default } className="inline-block mr-[10px]" alt="" />{" "}
                Telegram Messages
              </td>
              <td className="text-right">0/45</td>
            </tr>
            
            <tr>
              <td className="flex justify-start items-center text-sm">
                {" "}
                <img src={ require('./icons/DashBoard/table 1/linemessages.svg').default } className="inline-block mr-[10px]" alt="" />{" "}
                Line Messages
              </td>
              <td className="text-right">0/45</td>
            </tr>
            
            <tr>
              <td className="flex justify-start items-center text-sm">
                {" "}
                <img src={ require('./icons/DashBoard/table 1/linescalls.svg').default } className="inline-block mr-[10px]" alt="" />{" "}
                Line Calls
              </td>
              <td className="text-right">0/45</td>
            </tr>
            
            <tr>
              <td className="flex justify-start items-center text-sm">
                {" "}
                <img src={ require('./icons/DashBoard/table 2/whatshapp.svg').default } className="inline-block mr-[10px]" alt="" />{" "}
                WhatsApp
              </td>
              <td className="text-right">0/45</td>
            </tr>
            
            <tr>
              <td className="flex justify-start items-center text-sm">
                {" "}
                <img src={ require('./icons/DashBoard/table 2/whatshapp.svg').default } className="inline-block mr-[10px]" alt="" />{" "}
                WhatsApp Calls
              </td>
              <td className="text-right">0/45</td>
            </tr>
            
            <tr>
              <td className="flex justify-start items-center text-sm">
                {" "}
                <img src={ require('./icons/DashBoard/table 2/skypecalls.svg').default } className="inline-block mr-[10px]" alt="" />{" "}
                Skype Calls
              </td>
              <td className="text-right">0/45</td>
            </tr>
            
            <tr>
              <td className="flex justify-start items-center text-sm">
                {" "}
                <img src={ require('./icons/DashBoard/table 2/skypecalls.svg').default } className="inline-block mr-[10px]" alt="" />{" "}
                Skype Messages
              </td>
              <td className="text-right">0/45</td>
            </tr>
            
            <tr>
              <td className="flex justify-start items-center text-sm">
                {" "}
                <img src={ require('./icons/DashBoard/table 2/vibercalls.svg').default } className="inline-block mr-[10px]" alt="" />{" "}
                Viber Calls
              </td>
              <td className="text-right">0/45</td>
            </tr>
            
            <tr>
              <td className="flex justify-start items-center text-sm">
                {" "}
                <img src={ require('./icons/DashBoard/table 2/vibercalls.svg').default } className="inline-block mr-[10px]" alt="" />{" "}
                Viber messages
              </td>
              <td className="text-right">0/45</td>
            </tr>
            
            <tr>
              <td className="flex justify-start items-center text-sm">
                {" "}
                <img src={ require('./icons/DashBoard/table 2/facebookmessages.svg').default } className="inline-block mr-[10px]" alt="" />{" "}
                Facebook Messages
              </td>
              <td className="text-right">0/45</td>
            </tr>
            
            <tr>
              <td className="flex justify-start items-center text-sm">
                {" "}
                <img src={ require('./icons/DashBoard/table 2/facebookmessages.svg').default } className="inline-block mr-[10px]" alt="" />{" "}
                Messsaneger lite
              </td>
              <td className="text-right">0/45</td>
            </tr>
            
            <tr>
              <td className="flex justify-start items-center text-sm">
                {" "}
                <img src={ require('./icons/DashBoard/table 2/browserhistory.svg').default } className="inline-block mr-[10px]" alt="" />{" "}
               Browser History
              </td>
              <td className="text-right">0/45</td>
            </tr>
            
            <tr>
              <td className="flex justify-start items-center text-sm">
                {" "}
                <img src={ require('./icons/DashBoard/table 2/browserbookmarks.svg').default } className="inline-block mr-[10px]" alt="" />{" "}
                Browser Bookmarks
              </td>
              <td className="text-right">0/45</td>
            </tr>
            
          </table>
        </div>
      </div>
    </div>
  );
};

export default DashBoard;
