import React from "react";
import Navbar from "./components/Navbar";

const TellegramMessages = () => {
  return (
    <div className=" absolute h-full w-full right-0 p-4 lg:p-4 overflow-x-auto">
      <Navbar />
      <div className="h-auto mt-8 grid lg:grid-cols-2 md:grid-cols-2 gap-3 lg:gap-4">
        <div className="bg-white px-7 py-8 h-auto w-auto rounded-2xl flex flex-col lg:gap-1">
          <div className="text-[#2C294B] flex items-center justify-between">
            <div>
              <h2 className="text-base font-bold">Jessica Cox Anderson</h2>
              <p className="text-[16px]">+1 (702) 452-5559</p>
            </div>
            <div>
              <p className="text-[#ADB0BE]">22 Jan 2014 23:23</p>
              <p className="text-[#009EF7] text-xl font-semibold">Sent</p>
            </div>
          </div>
          <p className="text-base">
          Why are you texting me so much?.
          </p>
        </div>

        <div className="bg-white px-10 py-8 h-auto w-auto rounded-2xl flex flex-col lg:gap-1">
          <div className="text-[#2C294B] flex items-center justify-between">
            <div>
              <h2 className="text-base font-bold">Jessica Cox Anderson</h2>
              <p className="text-[16px]">+1 (702) 452-5559</p>
            </div>
            <div>
              <p className="text-[#ADB0BE]">22 Jan 2014 23:23</p>
              <p className="text-[#2b2b2b] text-xl font-semibold">Received</p>
            </div>
          </div>
          <p className="text-base">
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
            nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
            erat, sed diam voluptua. At vero eos et accu
          </p>
        </div>

        <div className="bg-white px-10 py-8 h-auto w-auto rounded-2xl flex flex-col lg:gap-1">
          <div className="text-[#2C294B] flex items-center justify-between">
            <div>
              <h2 className="text-base font-bold">Jessica Cox Anderson</h2>
              <p className="text-[16px]">+1 (702) 452-5559</p>
            </div>
            <div>
              <p className="text-[#ADB0BE]">22 Jan 2014 23:23</p>
              <p className="text-[#009ef7] text-xl font-semibold">Sent</p>
            </div>
          </div>
          <p className="text-base">
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam 
nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, 
sed diam voluptua. At vero eos et accu
          </p>
        </div>
        <div className="bg-white px-10 py-8 h-auto w-auto rounded-2xl flex flex-col lg:gap-1">
          <div className="text-[#2C294B] flex items-center justify-between">
            <div>
              <h2 className="text-base font-bold">Jessica Cox Anderson</h2>
              <p className="text-[16px]">+1 (702) 452-5559</p>
            </div>
            <div>
              <p className="text-[#ADB0BE]">22 Jan 2014 23:23</p>
              <p className="text-[#009EF7] text-xl font-semibold">Sent</p>
            </div>
          </div>
          <p className="text-base">JWhy are you texting me so much?</p>
        </div>
        <div className="bg-white px-10 py-8 h-auto w-auto rounded-2xl flex flex-col lg:gap-1">
          <div className="text-[#2C294B] flex items-center justify-between">
            <div>
              <h2 className="text-base font-bold">Jessica Cox Anderson</h2>
              <p className="text-[16px]">+1 (702) 452-5559</p>
            </div>
            <div>
              <p className="text-[#ADB0BE]">22 Jan 2014 23:23</p>
              <p className="text-[#009EF7] text-xl font-semibold">Sent</p>
            </div>
          </div>
          <p className="text-base">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam 
nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, 
sed diam voluptua. At vero eos et accu</p>
        </div>
        <div className="bg-white px-10 py-8 h-auto w-auto rounded-2xl flex flex-col lg:gap-1">
          <div className="text-[#2C294B] flex items-center justify-between">
            <div>
              <h2 className="text-base font-bold">Jessica Cox Anderson</h2>
              <p className="text-[16px]">+1 (702) 452-5559</p>
            </div>
            <div>
              <p className="text-[#ADB0BE]">22 Jan 2014 23:23</p>
              <p className="text-[#2C294B] text-xl font-semibold">Recevied</p>
            </div>
          </div>
          <p className="text-base">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam 
nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, 
sed diam voluptua. At vero eos et accu</p>
        </div>
        <div className="bg-white px-10 py-8 h-auto w-auto rounded-2xl flex flex-col lg:gap-1">
          <div className="text-[#2C294B] flex items-center justify-between">
            <div>
              <h2 className="text-base font-bold">Jessica Cox Anderson</h2>
              <p className="text-[16px]">+1 (702) 452-5559</p>
            </div>
            <div>
              <p className="text-[#ADB0BE]">22 Jan 2014 23:23</p>
              <p className="text-[#2C294B] text-xl font-semibold">Recevied</p>
            </div>
          </div>
          <p className="text-base">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam 
nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,</p>
        </div>
        <div className="bg-white px-10 py-8 h-auto w-auto rounded-2xl flex flex-col lg:gap-1">
          <div className="text-[#2C294B] flex items-center justify-between">
            <div>
              <h2 className="text-base font-bold">Jessica Cox Anderson</h2>
              <p className="text-[16px]">+1 (702) 452-5559</p>
            </div>
            <div>
              <p className="text-[#ADB0BE]">22 Jan 2014 23:23</p>
              <p className="text-[#009EF7] text-xl font-semibold">Sent</p>
            </div>
          </div>
          <p className="text-base">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam 
nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, 
sed diam voluptua. At vero eos et accu</p>
        </div>
      </div>
    </div>
  );
};
export default TellegramMessages;
